import React, { useState } from 'react';
import { Layout } from 'antd';
// import Header from './Header';
import NavigationMenu from './NavigationMenu';
import HeaderComponent from './Header';
import './layout.less';
import { WinrateImages } from '../assets/Images';

interface MainLayoutProps {
  children?: React.ReactNode; // More specific type for children
  collapsed?: boolean;
  setCollapsed?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { Content, Sider, Header } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const { winrateLogo, winrateLogoCollapse } = WinrateImages.Icons;

  return (
    <>
      <Layout className="layout">
        <Sider
          className={collapsed ? 'siderbar collapse-menu' : 'siderbar'}
          width="250px"
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          {collapsed ? (
            <img
              src={winrateLogoCollapse}
              alt="winrate-log"
              className="collapse-logo"
            />
          ) : (
            <img src={winrateLogo} alt="winrate-log" className="logo" />
          )}
          <NavigationMenu collapsed={collapsed} />
        </Sider>
        <Layout>
          <Header>
            <HeaderComponent
              setCollapsed={setCollapsed}
              collapsed={collapsed}
            />
          </Header>
          <Content
            className={
              window.location.pathname === '/team-detail'
                ? 'custom-main'
                : 'main'
            }
            id="main"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default MainLayout;

/* eslint-disable react/prop-types */
import {
  Checkbox,
  Col,
  DatePicker,
  Modal,
  ModalProps,
  Row,
  TimePicker,
  Typography,
} from 'antd';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import { useState } from 'react';

const { Text, Title } = Typography;

interface Inummodal extends ModalProps {
  onClose: () => void;
  setSnooze: (value: number[]) => void;
}

const options = [
  {
    key: 1,
    label: 'All',
  },
  {
    key: 2,
    label: 'Email',
  },
  {
    key: 3,
    label: 'Slack',
  },
  {
    key: 4,
    label: 'Text',
  },
  {
    key: 5,
    label: 'In-App',
  },
];

const Snooze: React.FC<Inummodal> = ({ onClose, setSnooze }) => {
  const [selectedKeys, setSelectedKeys] = useState<number[]>([]);

  const handleCheckboxChange = (checked: boolean, key: number) => {
    setSelectedKeys((prev) => {
      if (checked) {
        return [...prev, key];
      } else {
        return prev.filter((item) => item !== key);
      }
    });
  };

  const handleCancel = () => {
    onClose();
  };

  const handleselect = () => {
    setSnooze(selectedKeys);
    console.log(selectedKeys);
    onClose();
  };

  return (
    <Modal
      width={600}
      centered
      open={true}
      closable={false}
      footer={[
        <>
          <SecondaryButton text="Cancel" key="1" onClick={handleCancel} />
          <PrimaryButton text="Snooze" key="2" onClick={handleselect} />,
        </>,
      ]}
      className="success-modal"
    >
      <Row
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Col span={24}>
          <Title level={4}>Snooze notifications until</Title>
        </Col>
        <Col style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <Row style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Text style={{ color: '#8C8C8C' }}>Notification Type</Text>
            <Col style={{ display: 'flex', gap: '16px' }}>
              {options.map((option) => (
                <Checkbox
                  key={option.key}
                  checked={selectedKeys.includes(option.key)}
                  onChange={(e) =>
                    handleCheckboxChange(e.target.checked, option.key)
                  }
                >
                  {option.label}
                </Checkbox>
              ))}
            </Col>
          </Row>
        </Col>
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <Text style={{ color: '#8C8C8C' }}>Snooze until</Text>
          <Col style={{ display: 'flex', gap: '12px' }}>
            <DatePicker />
            <TimePicker />
          </Col>
        </Col>
      </Row>
    </Modal>
  );
};

export default Snooze;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Steps,
  Typography,
  Row,
  Col,
  Divider,
  Space,
  Skeleton,
  Upload,
  UploadProps,
  message,
} from 'antd';
import { WinrateImages } from '../../assets/Images';
import PrimaryButton from '../../common/PrimaryButton';
import SecondaryButton from '../../common/SecondaryButton';
import { useNavigate, useParams } from 'react-router-dom';
import { generateClient } from 'aws-amplify/data';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { setUserID } from '../../store/authSlice';
import {
  CheckCircleFilled,
  UploadOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { uploadData } from 'aws-amplify/storage';

const { Title, Text } = Typography;
const { Step } = Steps;
interface CompanyDetails {
  name: string; // Add other properties as needed
  domain: string; // Add other properties as needed
  logoUrl: string; // Add other properties as needed
}

interface SetupAccountProps {
  redirectLoader: boolean; // Define the type of redirectLoader
  setRedirectLoader: Dispatch<SetStateAction<boolean>>;
}

const SetupAccount: React.FC<SetupAccountProps> = ({
  redirectLoader,
  setRedirectLoader,
}) => {
  const {
    authBG,
    loginLogo,
    salesForce,
    hubSpot,
    slack,
    connectedIcon,
    calendarPlaceholder,
  } = WinrateImages.Auth;

  const { stepId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  type ClientSchema = any;
  const client = generateClient<ClientSchema>({ authMode: 'apiKey' });
  const [form] = Form.useForm();
  const [companyDetails, setcompanyDetails] = useState<CompanyDetails | null>(
    null
  );
  const [usersEmail, setUsersEmail] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [loader] = useState(false);
  const [uploadLogoFile, setUplodLogoFile] = useState<RcFile | undefined>(
    undefined
  );
  const [imageView, setImageView] = useState<string | undefined>(undefined);
  const [uploadLogo, setUploadLogo] = useState(false);
  const [setep1Loading, setStep1Loading] = useState(false);
  const [calendarLoading] = useState(false);
  const [usersID, setUsersID] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();
  const user_id = useSelector((state: RootState) => state.auth.userID);
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const currentPath = window.location.pathname;
  const originPath = currentPath === '/settings' ? 'notification' : 'default';

  //for logo upload
  const props: UploadProps = {
    name: 'file',

    onChange(info) {
      setUploadLogo(true);
      const file = info.fileList[0].originFileObj;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageView(e?.target?.result as string);
        };
        setUplodLogoFile(file);
        reader.readAsDataURL(file);
      }
    },
  };

  const fetchUserID = async () => {
    try {
      const data = await fetchUserAttributes();
      setUsersID(data?.sub);
    } catch (err) {
      console.log(err);
    }
  };

  //fetch company details from domain name
  const fetchCompanyDetails = async () => {
    setLoading(true);
    setRedirectLoader(true);
    try {
      if (localStorage.getItem('User')) {
        const userEmail = localStorage.getItem('User');
        if (userEmail) {
          setUsersEmail(userEmail);
        }
        const res = await client.queries.getOrganizationDetails({
          domain: userEmail?.split('@')[1],
        });
        setcompanyDetails(JSON.parse(res.data.response));
      } else {
        const data = await fetchUserAttributes();

        const userEmail = data.email;
        setUsersEmail(userEmail);
        const res = await client.queries.getOrganizationDetails({
          domain: userEmail?.split('@')[1],
        });

        setcompanyDetails(JSON.parse(res.data.response));
      }
      setLoading(false);
      setRedirectLoader(false);
    } catch (err: any) {
      setLoading(false);
      setRedirectLoader(false);
    }
  };

  // Navigate to the next step and update the URL
  const nextStep = () => {
    const nextStepIndex = currentStep + 1;
    setCurrentStep(nextStepIndex);
    navigate(`/setup-account/${nextStepIndex}`);
  };

  //create a new organization
  const createOrgandUser = async () => {
    setStep1Loading(true);

    const data = await fetchUserAttributes();
    const formValue = form.getFieldsValue();
    let uploadImageinS3;
    if (uploadLogoFile) {
      uploadImageinS3 = await uploadData({
        path: `logo/${uploadLogoFile?.name}`,
        data: uploadLogoFile,
      }).result;
    }
    try {
      const input = {
        id: uuidv4(),
        cognito_user_id: data.sub,
        first_name: formValue.username.split(' ')[0],
        last_name: formValue.username.split(' ')[1],
        domain: companyDetails?.domain
          ? companyDetails?.domain
          : formValue.domain,
        logo: companyDetails?.logoUrl
          ? companyDetails?.logoUrl
          : uploadImageinS3?.path,
        name: companyDetails?.name || formValue.name,
        email: usersEmail,
      };

      const result = await client.queries.createUserWithOrganization(input);
      const resp = JSON.parse(result.data.response);
      dispatch(setUserID(resp.user.id));
      localStorage.setItem('user_id', resp.user.id);
      message.success('Organization created successfully');
      nextStep();
      setStep1Loading(false);
    } catch (err: any) {
      setStep1Loading(false);
      message.error(err.message);
    }
  };

  const connectCalendar = async () => {
    try {
      const redirect_uri = process.env.REACT_APP_REDIRECT_URL;
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?state=${usersID}&client_id=${clientId}&scope=https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/gmail.readonly&redirect_uri=${redirect_uri}&prompt=consent&response_type=code&include_granted_scopes=true&access_type=offline`;
    } catch (error) {
      console.error('Error during authentication:', error);
    }
  };

  //connect function for salesforce
  const connectSalesForce = async () => {
    const encodedState = encodeURIComponent(`${usersID}|${originPath}`);
    try {
      window.location.href = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${process.env.REACT_APP_SALESFORCE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SALESFORCE_REDIRECT_URL}&state=${encodedState}`;
    } catch (err: any) {
      message.error(err.message);
    }
  };

  //connect function for slack
  const connectSlack = async () => {
    const encodedState = encodeURIComponent(`${usersID}|${originPath}`);
    try {
      window.location.href = `https://slack.com/oauth/v2/authorize?scope=chat:write,im:write,users:read,users:read.email&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URL}&client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&tracked=1&state=${encodedState}`;
    } catch (err: any) {
      message.error(err.message);
    }
  };

  const connectHubspot = async () => {
    const encodedState = encodeURIComponent(`${usersID}|${originPath}`);
    try {
      window.location.href = `https://app.hubspot.com/oauth-bridge?client_id=${process.env.REACT_APP_HUBSPOT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HUBSPOT_REDIRECT_URL}&scope=crm.objects.users.read%20oauth%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.contacts.read%20cms.domains.read&state=${encodedState}`;
    } catch (err: any) {
      message.error(err.message);
    }
  };

  useEffect(() => {
    fetchCompanyDetails();
    fetchUserID();
    if (!user_id) {
      const localUserID = localStorage.getItem('user_id');
      if (localUserID) {
        dispatch(setUserID(localUserID));
      }
    }
    // eslint-disable-next-line
  }, []);

  // Update the current step based on the stepId from the route
  useEffect(() => {
    if (stepId) {
      setCurrentStep(parseInt(stepId, 10) || 0);
    }
  }, [stepId]);

  const handleStepChange = () => {
    navigate(`/setup-account/${currentStep}`);
  };

  //to seth the  form value
  useEffect(() => {
    form.setFieldsValue({
      name: companyDetails?.name || '',
      domain: companyDetails?.domain || '',
      logo: companyDetails?.logoUrl || '',
    });

    // eslint-disable-next-line
  }, [companyDetails]);

  useEffect(() => {
    if (currentStep === 2) {
      const fromAuthCallback = new URLSearchParams(window.location.search);
      const getType = fromAuthCallback.get('connect_type');
      const authSuccess = fromAuthCallback.get('auth');
      if (getType === 'slack') {
        localStorage.setItem('slackConnect', authSuccess as string);
      } else if (getType === 'salesforce') {
        localStorage.setItem('salesForcceConnect', authSuccess as string);
      } else if (getType === 'hubspot') {
        localStorage.setItem('hubspotConnect', authSuccess as string);
      }
      navigate('/setup-account/2');
    } else if (currentStep === 1) {
      const fromAuthCallback = new URLSearchParams(window.location.search);
      const getType = fromAuthCallback.get('connect_type');
      const authSuccess = fromAuthCallback.get('auth');
      if (getType === 'google_calendar') {
        localStorage.setItem('googleConnect', authSuccess as string);
      }
      navigate('/setup-account/1');
    }

    // eslint-disable-next-line
  }, [currentStep]);

  const steps = [
    {
      title: '',
      content: (
        <Row className="signup-text">
          <Col span={9}>
            <Form
              requiredMark={false}
              layout="vertical"
              form={form}
              onFinish={createOrgandUser}
            >
              <Form.Item
                label="Full name"
                name="username"
                rules={[
                  { required: true, message: 'Please input your full name!' },
                  {
                    pattern: /^[a-zA-Z\s]+$/,
                    message: 'Symbols and number are not allowed',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Company name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company name!',
                  },
                ]}
              >
                {loading ? (
                  <Skeleton.Input active className="w-100" />
                ) : (
                  <Input disabled={companyDetails?.name ? true : false} />
                )}
              </Form.Item>

              <Form.Item
                label="Company website"
                name="domain"
                rules={[
                  {
                    required: true,
                    message: 'Please input your domain!',
                  },
                ]}
              >
                {loading ? (
                  <Skeleton.Input active className="w-100" />
                ) : (
                  <Input disabled={companyDetails?.domain ? true : false} />
                )}
              </Form.Item>
              <Form.Item
                label="Your logo"
                name="logo"
                rules={[
                  {
                    required: true,
                    message: 'Please upload your logo!',
                  },
                ]}
              >
                {loading ? (
                  <Skeleton.Input active className="w-100" />
                ) : companyDetails?.logoUrl || uploadLogo ? (
                  <div className="static-image">
                    <img
                      className="company-logo"
                      src={
                        companyDetails?.logoUrl
                          ? companyDetails?.logoUrl
                          : imageView
                      }
                    />
                    {uploadLogo && (
                      <CloseCircleOutlined
                        className="close-icon"
                        onClick={() => {
                          setUploadLogo(false);
                        }}
                      />
                    )}
                  </div>
                ) : (
                  !uploadLogo && (
                    <Upload {...props}>
                      <Button icon={<UploadOutlined />}>Upload Logo</Button>
                    </Upload>
                  )
                )}
              </Form.Item>
              <Form.Item className="signup-text">
                <PrimaryButton
                  text="Continue"
                  disabled={loading}
                  loading={setep1Loading}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      ),
    },
    {
      title: '',
      content: (
        <>
          <Row justify="space-between" className="calendar-step">
            <Col span={14}>
              <Row align="middle">
                <Typography.Text type="secondary">
                  Connect your Google Calendar to move forward.
                </Typography.Text>
              </Row>
              <Typography.Title level={4} className="fw-500">
                Syncing your calendar allows us to <br /> pull in all your
                scheduled meetings
              </Typography.Title>
              <Space align="baseline">
                <CheckCircleFilled style={{ color: 'green' }} />
                <Text>
                  Automatically generate meeting prep <br /> notes for you ahead
                  of time
                </Text>
              </Space>
              <br />
              <Space>
                <CheckCircleFilled style={{ color: 'green' }} />
                <Text>Helping you stay informed and prepared</Text>
              </Space>
            </Col>
            <Col span={10}>
              <img
                src={calendarPlaceholder}
                alt="calendar"
                className="calendar-placeholder"
              />
            </Col>
          </Row>
          <Row className="signup-text">
            {localStorage.getItem('googleConnect') === 'success' ? (
              <Button type="text" icon={<img src={connectedIcon} alt="" />}>
                Connected
              </Button>
            ) : (
              <PrimaryButton
                loading={calendarLoading}
                text="Connect to Calendar"
                onClick={connectCalendar}
              />
            )}
          </Row>
        </>
      ),
    },
    {
      title: '',
      content: (
        <>
          <Row justify="space-between">
            <Col span={21}>
              <Row align="middle">
                <img src={slack} alt="slack" />
                <Title level={5} className="mb-0 ml-10">
                  Slack
                </Title>
              </Row>
              <Text>
                Get instant notifications about important updates and meeting
                reminders directly in Slack.
              </Text>
            </Col>
            {localStorage.getItem('slackConnect') === 'success' ? (
              <Col span={3}>
                <Button type="text" icon={<img src={connectedIcon} alt="" />}>
                  Connected
                </Button>
              </Col>
            ) : (
              <Col span={2}>
                <PrimaryButton
                  text="Connect"
                  onClick={connectSlack}
                  disabled={loader}
                />
              </Col>
            )}
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={21}>
              <Row align="middle">
                <img src={salesForce} alt="salesForce" />
                <Title level={5} className="mb-0 ml-10">
                  SalesForce
                </Title>
              </Row>
              <Text>
                Connecting Salesforce lets you bring in valuable CRM data for
                your meeting prep.
              </Text>
            </Col>

            {localStorage.getItem('salesForcceConnect') === 'success' ? (
              <Col span={3}>
                <Button type="text" icon={<img src={connectedIcon} alt="" />}>
                  Connected
                </Button>
              </Col>
            ) : (
              <Col span={2}>
                <PrimaryButton
                  text="Connect"
                  onClick={connectSalesForce}
                  disabled={loader}
                />
              </Col>
            )}
          </Row>
          <Divider />
          <Row justify="space-between">
            <Col span={21}>
              <Row align="middle">
                <img src={hubSpot} alt="hubSpot" />
                <Title level={5} className="mb-0 ml-10">
                  HubSpot
                </Title>
              </Row>
              <Text>
                Sync up-to-date lead activities and engagement history to enrich
                your prep notes.
              </Text>
            </Col>
            {localStorage.getItem('hubspotConnect') === 'success' ? (
              <Col span={3}>
                <Button type="text" icon={<img src={connectedIcon} alt="" />}>
                  Connected
                </Button>
              </Col>
            ) : (
              <Col span={2}>
                <PrimaryButton text="Connect" onClick={connectHubspot} />
              </Col>
            )}
          </Row>
          <Row className="signup-text thrid-step-footer">
            <SecondaryButton
              text="Skip & Get Started"
              onClick={() => navigate('/')}
            />
          </Row>
        </>
      ),
    },
  ];

  return (
    <div className="auth-page">
      {redirectLoader && (
        <div className="redirect-overlay">
          <div className="loader"></div>
          <p className="ml-10"> Redirecting...</p>
        </div>
      )}
      <div className={redirectLoader ? 'redirect-bg auth-page' : 'auth-page'}>
        {/* Your main content goes here */}
        <img src={authBG} alt="" className="auth-bg" />
        <div className="onboarding-container email-form-container">
          <img src={loginLogo} alt="Winrate Logo" className="logo mb-20" />

          <Steps
            current={currentStep}
            className="custom-steps mb-20"
            onChange={handleStepChange}
          >
            {steps.map((item, index) => (
              <Step key={index} title={item.title} />
            ))}
          </Steps>
          {currentStep === 0 && (
            <>
              <Typography.Title level={4} className="signup-text fw-500">
                Set Up Your Account
              </Typography.Title>
              <Typography.Text className="signup-text text-secondary">
                Set up in no time and start closing deals faster
              </Typography.Text>
            </>
          )}
          <div className="step-content mt-24">{steps[currentStep].content}</div>

          <Row justify="space-between" className="account-footer">
            <div>
              <Typography.Text className="mr-5">
                Terms & Conditions
              </Typography.Text>
              <span className="text-secondary">• </span>
              <Typography.Text className="ml-5">Privacy Policy</Typography.Text>
            </div>
            <Typography.Text className="signup-text fs-12">
              © {new Date().getFullYear()}, Winrate. All Rights Reserved.
            </Typography.Text>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SetupAccount;

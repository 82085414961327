import SearchComponent from '../common/SearchComponent';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  message,
  Popover,
  Row,
  Tooltip,
  Typography,
  DatePicker,
  Select,
  Checkbox,
  Space,
  Skeleton,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './eventlisting.less';
import { WinrateImages } from '../assets/Images';
import { fetchUserAttributes } from 'aws-amplify/auth';
import {
  debounceFn,
  formatDate,
  formatTimeToLocal,
  isToday,
} from '../common/CommonMethod';
import dayjs from 'dayjs';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCheckedDomain,
  setDateChange,
  setEndDate,
  setMeetingEvents,
  setSelectedDate,
  setSelectedMonth,
  setStartDate,
} from '../store/authSlice';
import { createMeetingNotes, doGet } from '../sdk/Events';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../store/store';
import _ from 'lodash';
import SecondaryButton from '../common/SecondaryButton';

interface User {
  email: string;
  id: string;
}
interface Event {
  start_time: string;
  end_time: string;
  summary: string;
  external_domains: [];
  email: string;
  meeting_id: string;
  event_summary_id: string | null;
  // Add other properties as needed
}
interface EventsResponse {
  meetings: Event[];
}

type MeetingMetaData = {
  totalMeetingTime: string | null;
  externalMeetingsHours: string;
  internalMeetingsHours: string;
  internalMeetingsCount: number;
  externalMeetingsCount: number;
};
const EventList: React.FC = () => {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();

  const selectedMonth = useSelector(
    (state: RootState) => state.auth.selectedMonth
  );
  const selectedDate = useSelector(
    (state: RootState) => state.auth.selectedDate
  );
  const dateChange = useSelector((state: RootState) => state.auth.dateChange);
  const startDate = useSelector((state: RootState) => state.auth.startDate);
  const endDate = useSelector((state: RootState) => state.auth.endDate);
  const checkedDomain = useSelector(
    (state: RootState) => state.auth.checkedDomain
  );

  const [users, setUsers] = useState<User | undefined>(undefined);
  const [eventsResponse, setEventResponse] = useState<
    EventsResponse | undefined
  >(undefined);
  const [domainDetails, setDomainDetails] = useState<
    EventsResponse | undefined
  >(undefined);
  const [search, setSearch] = useState<string>('');

  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null]
  >([null, null]);

  const [firstCall, setFirstCall] = useState<boolean>(true);
  const [selectedFilters, setSelectedFilters] = useState(false);

  const dispatch = useDispatch();

  const { filterIcon, refreshIcon, summaryGeneratedIcon, prepNoteIcon } =
    WinrateImages.EventIcons;
  const [filterLoader, setFilterLoader] = useState(false);
  const [eventLoader, setEventLoader] = useState(true);
  const [createLoader, setCreateLoader] = useState('');
  const [initialMeetingMetaData, setInitialMeetingMetaData] =
    useState<MeetingMetaData | null>(null);

  const handlePrevMonth = () => {
    if (!startDate && !endDate) {
      const prevMonthDate = selectedDate.subtract(1, 'month');

      dispatch(setSelectedMonth(prevMonthDate));
      dispatch(setSelectedDate(prevMonthDate));
    }
  };

  const handleNextMonth = () => {
    if (!startDate && !endDate) {
      const nextMonthDate = selectedDate.add(1, 'month');
      dispatch(setSelectedMonth(nextMonthDate));
      dispatch(setSelectedDate(nextMonthDate));
    }
  };

  const fetchTodayDate = () => {
    dispatch(setDateChange(true));
    dispatch(setSelectedDate(dayjs()));
    dispatch(setSelectedMonth(dayjs()));
  };

  const fetchUsersID = async () => {
    try {
      const response = await fetchUserAttributes();
      const email = response?.email || '';
      const id = response?.sub || '';
      setUsers({ email, id });
    } catch (error: any) {
      return null;
    }
  };

  const [searchText, setSearchText] = useState<string | null>(null);
  const onSearch = async (searchText: string) => {
    if (searchText !== '' && users !== undefined) {
      setSearchText(searchText);
      await fetchCalendarEvents(searchText);
    } else {
      setSearchText(null);
      await fetchCalendarEvents('');
    }
  };

  // Use useCallback to memoize the debounced function
  const debouncedHandleInput = useCallback(debounceFn(onSearch, 1500), [
    selectedDate,
    users,
    startDate,
    endDate,
    initialMeetingMetaData,
  ]);

  const handleInputSearch = async (searchText: string) => {
    setSearch(searchText);
    debouncedHandleInput(searchText);
  };

  // Utility to group events by date
  const groupByDate = (events: Event[]) => {
    return events.reduce((acc: Record<string, Event[]>, event) => {
      const eventDate = new Date(event.start_time);
      // create YYYY-MM-DD date string while maintaining the time zone

      eventDate.setUTCHours(0, 0, 0, 0);
      const eventDateStr = eventDate.toISOString().split('T')[0];
      if (!acc[eventDateStr]) {
        acc[eventDateStr] = [];
      }
      acc[eventDateStr].push(event);
      return acc;
    }, {});
  };

  const groupedEvents = groupByDate(eventsResponse?.meetings || []);

  const handleDateFilter = async (value: any) => {
    setDateRange(value);
    await showExternalDomains(value?.[0], value?.[1]);
  };

  const uniqueDomainsSet = new Set<string>();

  const options =
    domainDetails?.meetings?.flatMap(
      (event) =>
        event?.external_domains
          ?.filter((domain: string) => {
            const domainName = domain?.split('@')[1]; // Extract the domain part (e.g., "winrate.com")

            if (
              domainName &&
              domainName !== 'gmail.com' &&
              !uniqueDomainsSet.has(domainName)
            ) {
              uniqueDomainsSet.add(domainName);
              return true; // Keep unique domain name
            }
            return false; // Skip duplicates
          })
          .map((domain: string, index) => ({
            key: index,
            label: domain?.split('@')[1], // Extract domain for label
            value: domain,
          })) || []
    ) || [];

  const clearFilters = () => {
    dispatch(setStartDate(''));
    dispatch(setEndDate(''));
    setSelectedDomains([]);
    setDateRange([null, null]);
    dispatch(setCheckedDomain([]));
  };

  const generateMeetingThenRedirect = async (event: any) => {
    if (event.event_summary_id) {
      navigate(`/notes/${event.event_summary_id}`);
      return;
    }
    setCreateLoader(event.meeting_id);
    const domains = event.external_domains.map(
      (email: string) => email.split('@')[1]
    );
    const domain = domains?.[0];
    const payload = {
      start_time: event.start_time,
      end_time: event.end_time,
      description: event.summary,
      summary: event.summary,
      attendees: event.attendees.map((attendee: any) => attendee.email),
      organizer: event.organizer?.email,
      meeting_id: event.meeting_id,
      domain,
    };
    const response = await createMeetingNotes(payload);
    if (response && response?.data?.summaryId) {
      navigate(`/notes/${response?.data?.summaryId}`);
    }
  };

  function Events(): React.ReactNode {
    return Object.keys(groupedEvents).map((item) => (
      <div key={item} className="mb-30">
        <div className="define-width mt-20">
          <Typography.Text
            className={isToday(item) ? 'today-text' : 'upcoming-text'}
          >
            {formatDate(item)}
          </Typography.Text>
        </div>
        <div className="eventlist-group define-width">
          {groupedEvents[item].map((event, index) => {
            const uniqueDomains: string[] = [];
            event.external_domains.forEach((email: string) => {
              const domain = email.split('@')[1];
              if (
                domain &&
                domain !== 'gmail.com' &&
                !uniqueDomains.includes(domain)
              ) {
                uniqueDomains.push(domain);
              }
            });

            return (
              <Row
                key={index}
                className={
                  isToday(item) ? 'mb-20 eventlist-row' : 'eventlist-row'
                }
              >
                <div className="eventlist-row-date">
                  <span className="start-time">
                    {formatTimeToLocal(event.start_time)}{' '}
                  </span>
                  <span className="end-time-font">
                    {formatTimeToLocal(event.end_time)}{' '}
                  </span>
                </div>

                <Divider type="vertical" className="event-divider" />
                <Typography.Text className="event-title">
                  {event.summary}
                </Typography.Text>

                <div className="eventlist-row-extra">
                  {uniqueDomains
                    .slice(0, 2)
                    .map((domain: string, key: number) => (
                      <img
                        key={key}
                        alt="example"
                        src={`https://logo.clearbit.com/${domain}`}
                        className="company-logos"
                      />
                    ))}
                  {uniqueDomains.length > 2 && (
                    <span>+ {uniqueDomains.length - 2}</span>
                  )}

                  <Divider type="vertical" className="logo-divider" />
                  <Tooltip
                    title={
                      event.event_summary_id
                        ? 'View prep note'
                        : 'Generate prep note'
                    }
                  >
                    <Button
                      type="text"
                      className="p-0"
                      onClick={() => generateMeetingThenRedirect(event)}
                    >
                      {createLoader === event.meeting_id ? (
                        <LoadingOutlined style={{ fontSize: '18px' }} />
                      ) : event.event_summary_id ? (
                        <img src={prepNoteIcon} alt="prepnote" />
                      ) : (
                        <img
                          src={summaryGeneratedIcon}
                          alt="summarygenerated"
                        />
                      )}
                    </Button>
                  </Tooltip>
                </div>
              </Row>
            );
          })}
        </div>
      </div>
    ));
  }
  function FilterModal() {
    return (
      <Popover
        trigger="click"
        placement="bottom"
        content={
          <>
            <Row align="middle" justify="space-between" className="mb-10">
              <Typography.Title className="fw-500 mb-0" level={5}>
                Filters
              </Typography.Title>
              <Row>
                <Button
                  className="p-0 fw-500"
                  type="text"
                  onClick={() => {
                    clearFilters();
                    setSelectedFilters(false);
                  }}
                >
                  Clear
                </Button>
                <Button
                  loading={filterLoader}
                  className="p-0 branding-color fw-500 ml-20"
                  type="text"
                  onClick={() => {
                    dispatch(
                      setStartDate(dateRange?.[0]?.format('YYYY-MM-DD'))
                    );
                    dispatch(setEndDate(dateRange?.[1]?.format('YYYY-MM-DD')));
                    dispatch(setCheckedDomain(selectedDomains));
                    setSelectedFilters(true);
                  }}
                >
                  Save Filter
                </Button>
              </Row>
            </Row>
            <Typography.Text type="secondary" className="mt-10">
              Date range
            </Typography.Text>
            <RangePicker
              value={dateRange}
              onChange={handleDateFilter}
              format={'YYYY-MM-DD'}
            />
            <div className="mt-20">
              <Typography.Text type="secondary">Company</Typography.Text>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                value={selectedDomains}
                onChange={(e: string[]) => setSelectedDomains(e)}
                options={options}
                optionRender={(option) => (
                  <Space>
                    <Checkbox
                      checked={selectedDomains.includes(String(option.value))}
                    >
                      <Row align="middle">
                        <img
                          src={`https://www.google.com/s2/favicons?domain=${option.label}&sz=64`}
                          alt=""
                          className="ml-5 domain-logo"
                        />
                        <span className="ml-5">{option.label}</span>
                      </Row>
                    </Checkbox>
                  </Space>
                )}
              ></Select>
            </div>
          </>
        }
      >
        <Row
          align="middle"
          className={
            selectedFilters
              ? 'cursor-pointer selected-filter'
              : 'cursor-pointer'
          }
        >
          <img src={filterIcon} alt="" />
          <Typography.Text className="ml-10">Filters</Typography.Text>
        </Row>
      </Popover>
    );
  }

  const fetchCalendarEvents = async (searchText = '') => {
    setFilterLoader(true);
    setEventLoader(true);

    try {
      // Set timeMin to today's start in UTC (00:00:00)

      const today = new Date();
      today.setUTCHours(0, 0, 0, 0);
      const timeMin = today.toISOString().split('.')[0] + 'Z';

      // Set timeMax to the end of the week in UTC (23:59:59)
      const endOfWeek = new Date(today);
      endOfWeek.setDate(today.getDate() + 7);
      endOfWeek.setUTCHours(23, 59, 59, 999);
      const timeMax = endOfWeek.toISOString().split('.')[0] + 'Z';

      // Determine start and end times based on date conditions
      const startDateTime = startDate
        ? new Date(startDate)
        : dateChange
          ? selectedDate.toDate()
          : selectedMonth.startOf('month').toDate();

      const endDateTime = endDate
        ? new Date(endDate)
        : dateChange
          ? selectedDate.toDate()
          : selectedMonth.endOf('month').toDate();

      // Set UTC time for startDateTime and endDateTime if provided
      if (startDateTime) startDateTime.setUTCHours(0, 0, 0, 0);
      if (endDateTime) endDateTime.setUTCHours(23, 59, 59, 999);

      // Prepare input data for fetching calendar events

      const timeMinimun = startDateTime
        ? startDateTime.toISOString()
        : firstCall
          ? timeMin
          : startDateTime.toISOString();
      const timeMaximum = endDateTime
        ? endDateTime.toISOString()
        : firstCall
          ? timeMax
          : endDateTime.toISOString();
      const userId = _.get(users, 'id');
      const domain = _.get(users, 'email', '').split('@')[1];
      const q = _.join([...selectedDomains, searchText], ', ');

      const filterTodayEvents = (events: any) => {
        const today = new Date().toISOString().slice(0, 10); // Format: YYYY-MM-DD

        return events.filter((event: any) =>
          event.start_time.startsWith(today)
        );
      };

      // Fetch calendar events using the inputData
      const path = `events?timeMin=${timeMinimun}&timeMax=${timeMaximum}&userId=${userId}&q=${q}&domain=${domain}`;

      const response = await doGet(path);
      if (response?.data) {
        const calendarData = response.data;
        setEventResponse(calendarData);
        const todayMeetings = filterTodayEvents(calendarData?.meetings);
        if (!initialMeetingMetaData) {
          setInitialMeetingMetaData(calendarData.meetingMetaData);
          dispatch(setMeetingEvents(calendarData));
        } else if (todayMeetings.length > 0) {
          // Update only if today's meetings are found
          dispatch(setMeetingEvents(calendarData));
        }
      }

      setFilterLoader(false);
      setEventLoader(false);
      setFirstCall(false);
      // Reset states if there was a date change
      if (dateChange) {
        setDateRange([null, null]);
        setSelectedDomains([]);
        dispatch(setDateChange(false));
      }
    } catch (err: any) {
      setFilterLoader(false);
      setEventLoader(false);
      message.error(err);
    }
  };

  const showExternalDomains = async (
    startMonthDate?: dayjs.Dayjs,
    endMonthDate?: dayjs.Dayjs
  ) => {
    try {
      // Get today's date (timeMin)
      const today = new Date();
      today.setUTCHours(0, 0, 0, 0);
      const timeMin = today.toISOString();
      // Get 7 days from today (timeMax)
      const endOfWeek = new Date();
      endOfWeek.setDate(today.getDate() + 7);
      endOfWeek.setUTCHours(23, 59, 59, 999);
      const timeMax = endOfWeek.toISOString();
      // Convert startDate and endDate to Date objects if they are not null
      const startDateTime = startMonthDate
        ? startMonthDate.toDate()
        : startDate
          ? new Date(startDate)
          : null;
      const endDateTime = endMonthDate
        ? endMonthDate.toDate()
        : endDate
          ? new Date(endDate)
          : null;
      // Set the time for startDate and endDate (if provided)
      if (startDateTime) {
        startDateTime.setUTCHours(0, 0, 0, 0);
      }
      if (endDateTime) {
        endDateTime.setUTCHours(23, 59, 59, 999);
      }

      const timeMinimun = startDateTime ? startDateTime.toISOString() : timeMin; // Ensure time is set
      const timeMaximum = endDateTime ? endDateTime.toISOString() : timeMax;
      const userId = users?.id;
      const q = '';
      const domain = _.get(users, 'email', '').split('@')[1];

      const path = `events?timeMin=${timeMinimun}&timeMax=${timeMaximum}&userId=${userId}&q=${q}&domain=${domain}`;
      const response = await doGet(path);
      if (response.data) {
        setDomainDetails(response.data);
      }
    } catch (err: any) {
      message.error(err);
    }
  };

  useEffect(() => {
    if (users) {
      fetchCalendarEvents();
    }
  }, [users, selectedDate, startDate, endDate, checkedDomain]);

  useEffect(() => {
    fetchUsersID();
  }, []);

  useEffect(() => {
    navigate('/');
    if (users) {
      showExternalDomains();
    }
  }, [users]);

  return (
    <div className="eventlisting">
      <div className="define-width mt-20">
        <Row justify="space-between">
          <Row align="middle">
            <LeftOutlined
              onClick={handlePrevMonth}
              className="change-icons"
              style={{
                cursor: startDate || endDate ? 'not-allowed' : 'pointer',
                color: startDate || endDate ? '#c8c8c8' : 'black',
                stroke: startDate || endDate ? '#c8c8c8' : 'black',
              }}
            />
            <Button
              type="text"
              className="p-0 ml-10 mr-10"
              onClick={fetchTodayDate}
              icon={<img src={WinrateImages.EventIcons.calendarIcon} />}
            />
            <RightOutlined
              onClick={handleNextMonth}
              className="change-icons"
              style={{
                cursor: startDate || endDate ? 'not-allowed' : 'pointer',
                color: startDate || endDate ? '#c8c8c8' : 'black',
                stroke: startDate || endDate ? '#c8c8c8' : 'black',
              }}
            />

            <Typography.Title level={5} className="fw-600 mb-0 ml-10">
              {startDate && endDate ? (
                <>
                  <span>{dayjs(startDate).format('DD, MMM, YYYY')} </span> -
                  <span>{dayjs(endDate).format('DD, MMM, YYYY')}</span>
                </>
              ) : (
                selectedDate.format('MMM, YYYY')
              )}
            </Typography.Title>
            <Button type="text" onClick={() => fetchCalendarEvents()}>
              <img src={refreshIcon} />
            </Button>
          </Row>

          {FilterModal()}
        </Row>
      </div>
      <div className="define-width mt-20">
        <SearchComponent
          placeholder="Search"
          value={search}
          onChange={(value: string) => handleInputSearch(value)}
        />
      </div>

      {eventLoader ? (
        <div className="define-width mt-20">
          <Skeleton paragraph={{ rows: 5 }} active className="mt-10" />
        </div>
      ) : eventsResponse?.meetings?.length ? (
        Events()
      ) : searchText && eventsResponse?.meetings?.length === 0 ? (
        <div className="no-meeting-wrapper">
          <img src={WinrateImages.EventIcons.noSearchIcon} />
          <Typography.Title level={3} className="mt-10 no-meeting-found-text ">
            No <span className="no-meeting-found-text-color">Matches</span>{' '}
            Found{' '}
          </Typography.Title>
          <Typography.Text>
            Looks like we couldn’t find anything. Try refining your search.{' '}
          </Typography.Text>
        </div>
      ) : (
        <div className="no-meeting-wrapper">
          <img src={WinrateImages.EventIcons.noMeetingFoundIcon} />
          <Typography.Title level={3} className="mt-10 no-meeting-found-text ">
            No Meetings{' '}
            <span className="no-meeting-found-text-color">Found</span>
          </Typography.Title>
          <Typography.Text>
            No scheduled meetings. When new meetings are added,{' '}
          </Typography.Text>
          <Typography.Text>
            your prep notes will appear here automatically to keep you prepared.
          </Typography.Text>
          <SecondaryButton
            className="mt-10"
            text="Jump to Current Month"
            onClick={() => fetchCalendarEvents()}
          />
        </div>
      )}
    </div>
  );
};

export default EventList;

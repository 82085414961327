/* eslint-disable react/prop-types */
import {
  Col,
  Modal,
  Row,
  Typography,
  Button,
  ModalProps,
  Form,
  Input,
} from 'antd';
import './notification.less';

const { Text, Title } = Typography;

interface Inummodal extends ModalProps {
  onClose: () => void;
  setsmsModal: (value: boolean) => void;
  Number: string;
  setPhoneadd: (value: boolean) => void;
  setSuccessModal: (value: boolean) => void;
}

const Verify: React.FC<Inummodal> = ({
  onClose,
  setsmsModal,
  Number,
  setPhoneadd,
  setSuccessModal,
}) => {
  // const [save, setsave] = useState(false);
  const [form] = Form.useForm();

  const handleCancel = () => {
    onClose();
  };

  const handleconformation = () => {
    setsmsModal(true);
    setPhoneadd(false);
    setSuccessModal(true);
  };

  return (
    <Modal
      footer={null}
      width={600}
      centered
      open={true}
      onCancel={handleCancel}
      className="addnumber-modal"
    >
      <Row className="addnumber-content">
        <Row gutter={12} className="addnumber-header">
          <Title level={4}>Add Your Number for SMS</Title>
          <Text>
            A verification code has been sent to <Text strong>{Number}</Text>.
            Please enter the code to add the number.
          </Text>
          <Col>
            <Form
              layout="vertical"
              className="login-form-fields"
              requiredMark={false}
              form={form}
              onFinish={() => handleconformation()}
            >
              <Form.Item
                label="Verification code"
                name="code"
                rules={[{ required: true, message: 'Please input your code!' }]}
              >
                <Input placeholder="6-digit verification code" />
              </Form.Item>
            </Form>
          </Col>
          <Col span={24} className="addnumber-actions">
            <Button
              className="button"
              type="primary"
              onClick={() => handleconformation()}
            >
              Save
            </Button>
            <Form.Item className="signup-text">
              <Button
                type="text"
                className="branding-color"
                // onClick={resendConfirmationCode}
                // loading={resetLoading}
              >
                Resend code
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

export default Verify;

import './App.less';
import React, { useEffect } from 'react';
import RoutesApp from './routes/Routes';
import { fetchUserAttributes, signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const App: React.FC = () => {
  const navigate = useNavigate();

  const fetchUsersID = async () => {
    try {
      await fetchUserAttributes();
    } catch (error: any) {
      if (
        error.message === 'User needs to be authenticated to call this API.'
      ) {
        signOut({
          global: false,
        }).then(() => {
          localStorage.clear();
          navigate('/login');
        });
      }
      return null;
    }
  };

  useEffect(() => {
    fetchUsersID();
  }, []);
  return (
    <div className="App">
      <RoutesApp />
    </div>
  );
};

export default App;

import { Col, Row, Tabs } from 'antd';

import React from 'react';
import Integrations from './components/Integrations';
import './settings.less';
import CustomResearch from './components/CustomResearch';
import Notifications from './components/Notifications/Notifications';
import GeneralSettings from './components/General/GeneralSettings';

const Settings: React.FC = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items = [
    {
      key: 'integrations',
      label: 'Integrations',
      children: <Integrations />,
    },
    {
      key: 'custom_research',
      label: 'Custom Research',

      children: <CustomResearch />,
    },

    {
      key: 'general',
      label: 'General',

      children: <GeneralSettings />,
    },
    {
      key: 'notifications',
      label: 'Notifications',

      children: <Notifications />,
    },
  ];

  return (
    <div className="settings">
      <Row justify={'center'}>
        <Col span={18}>
          <h2>Settings</h2>
          <Tabs
            className="mt-24 setting_tabs"
            defaultActiveKey="integrations"
            items={items}
            onChange={onChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Settings;

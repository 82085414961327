import React, { useState } from 'react';
import {
  Col,
  Divider,
  Radio,
  Row,
  Switch,
  TimePicker,
  Typography,
  RadioChangeEvent,
} from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Reorder, useMotionValue, useDragControls } from 'framer-motion';
// import Dragicon from 'src/assets/navigation-icons/Drag.svg';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import './general.less';

const { Title, Text } = Typography;
const company_name = 'CrowdStrike';

const initialItems = [
  { id: 1, name: `About ${company_name}`, order: 1, isHidden: false },
  { id: 2, name: 'How the call got set up', order: 2, isHidden: false },
  { id: 3, name: 'Who is coming to the call', order: 3, isHidden: false },
  { id: 4, name: 'Custom Research', order: 4, isHidden: false },
  { id: 5, name: 'Suggested questions', order: 5, isHidden: false },
];

interface GenerateTime {
  dayOfMeeting: boolean;
  time: Dayjs | null;
}

interface ItemType {
  id: number;
  name: string;
  order: number;
  isHidden: boolean;
}

const GeneralSettings: React.FC = () => {
  const [generateTime, setGenerateTime] = useState<GenerateTime>({
    dayOfMeeting: true,
    time: dayjs('06:00', 'HH:mm'),
  });
  const [items, setItems] = useState(initialItems);

  console.log('items', items);

  const handleGenerateTimeChange = (e: RadioChangeEvent) => {
    setGenerateTime((prev) => ({
      ...prev,
      dayOfMeeting: e.target.value === 'dayOfMeeting',
    }));
  };

  const handleTimeChange = (time: Dayjs | null) => {
    setGenerateTime((prev) => ({
      ...prev,
      time,
    }));
  };

  const updateOrderNumbers = (newOrder: ItemType[]) => {
    const updatedItems = newOrder.map((item, index) => ({
      ...item,
      order: index + 1,
    }));
    setItems(updatedItems);
  };

  const toggleItemDisabled = (id: number) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, isHidden: !item.isHidden } : item
      )
    );
  };

  return (
    <Row className="general-settings" style={{ marginTop: '30px' }}>
      <Col className="ant-col">
        <Title level={5}>Customized View</Title>
        <Text>
          Organize your Prep Note layout by dragging to reorder sections, or
          choose to hide them for a cleaner view.
        </Text>

        {/* Draggable List */}
        <Reorder.Group
          axis="y"
          onReorder={updateOrderNumbers}
          values={items} // Keep all items in the list
          className="dragged-wrapper"
        >
          {items.map((item) => (
            <Item key={item.id} item={item} onToggle={toggleItemDisabled} />
          ))}
        </Reorder.Group>
      </Col>

      <Divider style={{ maxWidth: '70%' }} />

      <div className="prep-note-settings">
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Title level={5}>Prep Note Settings</Title>
          </Col>

          <div className="ant-row">
            <Col span={24}>
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <Text>Choose which meetings are eligible for Prep Notes</Text>
                </Col>

                <Col span={22}>
                  <Text>External Meetings</Text>
                </Col>
                <Col span={2}>
                  <Switch />
                </Col>

                <Col span={22}>
                  <Text>Internal Meetings</Text>
                </Col>
                <Col span={2}>
                  <Switch />
                </Col>
              </Row>
            </Col>

            <Col span={24}>
              <Row gutter={[0, 8]}>
                <Col span={24} style={{ marginTop: '16px' }}>
                  <Text>Choose when to generate Prep Notes</Text>
                </Col>

                <Col span={10}>
                  <Radio.Group
                    onChange={(e) => handleGenerateTimeChange(e)}
                    value={
                      generateTime.dayOfMeeting ? 'dayOfMeeting' : 'dayBefore'
                    }
                  >
                    <Radio value="dayOfMeeting">
                      Generate on the day of meeting
                    </Radio>
                  </Radio.Group>
                </Col>
                <Col>
                  <TimePicker
                    value={generateTime.dayOfMeeting ? generateTime.time : null}
                    onChange={handleTimeChange}
                    disabled={!generateTime.dayOfMeeting}
                    format="h:mm A"
                  />
                </Col>

                <Col span={10}>
                  <Radio.Group
                    onChange={handleGenerateTimeChange}
                    value={
                      !generateTime.dayOfMeeting ? 'dayBefore' : 'dayOfMeeting'
                    }
                  >
                    <Radio value="dayBefore">
                      Generate on the day before meeting
                    </Radio>
                  </Radio.Group>
                </Col>
                <Col>
                  <TimePicker
                    value={
                      !generateTime.dayOfMeeting ? generateTime.time : null
                    }
                    onChange={handleTimeChange}
                    disabled={generateTime.dayOfMeeting}
                    format="h:mm A"
                  />
                </Col>
              </Row>
            </Col>
          </div>
        </Row>
      </div>
    </Row>
  );
};

interface ItemProps {
  item: ItemType;
  onToggle: (id: number) => void;
}

// eslint-disable-next-line react/prop-types
const Item: React.FC<ItemProps> = ({ item, onToggle }) => {
  const y = useMotionValue(0);
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      value={item}
      style={{ y }}
      dragListener={!item.isHidden} // Disable dragging if item is disabled
      dragControls={dragControls}
      className={`draggable-item ${item.isHidden ? 'disabled' : ''}`}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!item.isHidden && <ReorderIcon dragControls={dragControls} />}
        <span
          style={{
            paddingLeft: '3px',
          }}
        >
          <h4>{item.name}</h4>
        </span>
      </div>

      {item.isHidden ? (
        <EyeInvisibleOutlined
          onClick={() => onToggle(item.id)}
          style={{
            marginLeft: 'auto',
            cursor: 'pointer',
            color: 'gray',
            textDecoration: 'none',
          }}
        />
      ) : (
        <EyeOutlined
          onClick={() => onToggle(item.id)}
          style={{
            marginLeft: 'auto',
            cursor: 'pointer',
            color: undefined,
          }}
        />
      )}
    </Reorder.Item>
  );
};

interface ReorderIconProps {
  dragControls: any;
}

// Drag Icon Component
const ReorderIcon: React.FC<ReorderIconProps> = ({ dragControls }) => (
  <span onPointerDown={(e) => dragControls.start(e)} style={{ cursor: 'grab' }}>
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8268 4.00001C11.1814 4.0034 10.6682 4.5363 10.6704 5.20112C10.6726 5.87007 11.1978 6.40336 11.848 6.39697C12.4875 6.39058 13.0047 5.84715 13 5.18646C12.9952 4.5254 12.4725 3.99701 11.8268 4.00001Z"
        fill="#262626"
      />
      <path
        d="M7.17321 16C7.81934 15.9966 8.33143 15.4644 8.3296 14.7989C8.32777 14.1303 7.80181 13.5959 7.15203 13.6027C6.51321 13.609 5.99564 14.1528 6.00003 14.8135C6.00441 15.4735 6.52818 16.0034 7.17321 16Z"
        fill="#262626"
      />
      <path
        d="M11.8315 15.9998C12.4769 16.0006 12.997 15.4696 12.9996 14.8081C13.0022 14.1425 12.4755 13.6006 11.8286 13.6025C11.1872 13.6044 10.6697 14.141 10.67 14.804C10.6708 15.4688 11.1858 15.9991 11.8315 15.9998Z"
        fill="#262626"
      />
      <path
        d="M8.32987 5.19323C8.32767 4.52841 7.81158 3.99926 7.16581 4.00001C6.51603 4.00076 5.99555 4.54044 6.00029 5.20901C6.00504 5.86707 6.53174 6.40035 7.17385 6.39734C7.81596 6.39434 8.33206 5.85692 8.32987 5.19323Z"
        fill="#262626"
      />
      <path
        d="M8.32987 10.0041C8.33169 9.34149 7.81705 8.80482 7.17348 8.79806C6.53721 8.79167 6.00468 9.33209 6.00029 9.98902C5.99591 10.655 6.52005 11.2003 7.16581 11.2018C7.80573 11.2033 8.32804 10.6659 8.32987 10.0041Z"
        fill="#262626"
      />
      <path
        d="M12.9996 10.0018C13.0003 9.34299 12.4806 8.8037 11.8388 8.79768C11.1967 8.79167 10.6675 9.33848 10.6704 10.0052C10.673 10.6662 11.1971 11.2044 11.8355 11.2018C12.4762 11.1992 12.9989 10.6606 12.9996 10.0018Z"
        fill="#262626"
      />
    </svg>
  </span>
);

export default GeneralSettings;

import React, { useState } from 'react';
import BegginingResearchItem from './BegginingResearchItem';
import ResearchItems from './ResearchItems';
interface LinkItem {
  id: number;
  text: string;
  isEditing: boolean;
}

interface ResearchItem {
  id: number;
  mainComment: string;
  links: LinkItem[];
}

const Research: React.FC = () => {
  const [researchItems, setResearchItems] = useState<ResearchItem[]>([]);
  console.log('researchItems', researchItems);
  return (
    <div className="custom_research_add mt-24">
      {researchItems?.length === 0 ? (
        <BegginingResearchItem setResearchItems={setResearchItems} />
      ) : (
        <ResearchItems researchItems={researchItems} />
      )}
    </div>
  );
};

export default Research;

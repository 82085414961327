import { Button } from 'antd';
import React from 'react';

interface ButtonProps {
  text: string;
  onClick?: React.MouseEventHandler;
  className?: string;
}

const SecondaryButton: React.FC<ButtonProps> = ({
  text,
  onClick,
  className,
}) => {
  return (
    <Button className={`secondary-btn ${className}`} onClick={onClick}>
      {text}
    </Button>
  );
};

export default SecondaryButton;

import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import MainLayoutRoute from './MainLayoutRoute';
import Users from '../UserManagement/Pages/Users';
import GroupDetailPage from '../UserManagement/components/AccessGroup/GroupDetailPage';
import TeamDetails from '../UserManagement/components/Teams/TeamDetails';
import PublicLayoutRoute from './PublicLayoutRoute';
import Login from '../Auth/Login/Login';
import Signup from '../Auth/Signup/Signup';
import VerifyEmail from '../Auth/Signup/VerifyEmail';
import SetupAccount from '../Auth/Signup/SetupAccount';
import { Hub } from 'aws-amplify/utils';
import ForgotPassword from '../Auth/ForgotPassword/ForgotPassword';
import ResetPassword from '../Auth/ForgotPassword/ResetPassword';
import { MeetingNotes } from '../Notes/MeetingNotes';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/data';
import EventList from '../EventList/EventListing';
import Settings from '../Settings/index';

// Component that handles the Hub.listen logic
const AuthListener: React.FC<{
  setRedirectLoader: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setRedirectLoader }) => {
  const navigate = useNavigate();
  type ClientSchema = any;
  const client = generateClient<ClientSchema>({ authMode: 'apiKey' });

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', async ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          break;
        case 'customOAuthState':
          if (payload.data === 'signIn') {
            setRedirectLoader(true);
            const response = await fetchUserAttributes();

            const res = await client.queries.integrationStatus({
              email: response.email,
            });
            const usersDetails = JSON.parse(res?.data?.response);
            if (!usersDetails?.userInfo?.first_name) {
              navigate('/setup-account/0'); // Redirect for setup account flow when user doesn't exist in the database.
            } else if (!usersDetails?.google) {
              navigate('/setup-account/1'); // Default or fallback route
            } else {
              navigate('/');
            }
            setRedirectLoader(false);
          } else if (payload.data === 'signup') {
            setRedirectLoader(true);
            const response = await fetchUserAttributes();

            const res = await client.queries.integrationStatus({
              email: response.email,
            });
            const usersDetails = JSON.parse(res?.data?.response);
            if (!usersDetails?.userInfo?.first_name) {
              navigate('/setup-account/0'); // Redirect for setup account flow when user doesn't exist in the database.
            } else if (!usersDetails?.google) {
              navigate('/setup-account/1'); // Default or fallback route
            } else {
              navigate('/');
            }
            setRedirectLoader(false);
          } else {
            navigate('/'); // Default or fallback route
          }

          break;
        case 'signInWithRedirect':
          break;
      }
    });

    return unsubscribe;

    // eslint-disable-next-line
  }, [navigate]);

  return null; // This component doesn't render anything
};

const RoutesApp: React.FC = () => {
  const [redirectLoader, setRedirectLoader] = useState(false);

  return (
    <>
      <AuthListener setRedirectLoader={setRedirectLoader} />
      <Routes>
        <Route
          path="/"
          element={<MainLayoutRoute element={<EventList />}></MainLayoutRoute>}
        ></Route>

        <Route
          path="/notes/:id"
          element={
            <MainLayoutRoute element={<MeetingNotes />}></MainLayoutRoute>
          }
        ></Route>

        <Route
          path="/user-management"
          element={<MainLayoutRoute element={<Users />}></MainLayoutRoute>}
        ></Route>
        <Route
          path="/accessgroup-detail"
          element={
            <MainLayoutRoute element={<GroupDetailPage />}></MainLayoutRoute>
          }
        ></Route>
        <Route
          path="/team-detail"
          element={
            <MainLayoutRoute element={<TeamDetails />}></MainLayoutRoute>
          }
        ></Route>
        <Route
          path="/settings"
          element={<MainLayoutRoute element={<Settings />}></MainLayoutRoute>}
        ></Route>
        <Route
          path="/login"
          element={<PublicLayoutRoute element={<Login />}></PublicLayoutRoute>}
        ></Route>
        <Route
          path="/signup"
          element={<PublicLayoutRoute element={<Signup />}></PublicLayoutRoute>}
        ></Route>
        <Route
          path="/verify-email"
          element={
            <PublicLayoutRoute element={<VerifyEmail />}></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/setup-account/:stepId"
          element={
            <PublicLayoutRoute
              element={
                <SetupAccount
                  redirectLoader={redirectLoader}
                  setRedirectLoader={setRedirectLoader}
                />
              }
            ></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/forgot-password"
          element={
            <PublicLayoutRoute element={<ForgotPassword />}></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/reset-password?confirmationcode=:code+email=:email"
          element={
            <PublicLayoutRoute element={<ResetPassword />}></PublicLayoutRoute>
          }
        ></Route>
        <Route
          path="/reset-password"
          element={
            <PublicLayoutRoute element={<ResetPassword />}></PublicLayoutRoute>
          }
        ></Route>
      </Routes>
    </>
  );
};

export default RoutesApp;

import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Switch,
  TimePicker,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import Slackicon from 'src/assets/notification-icons/Slack.svg';
import Prepicon from 'src/assets/notification-icons/Prepnotes.svg';
import Group from 'src/assets/notification-icons/Group.svg';

import { EditOutlined } from '@ant-design/icons';
import './notification.less';
import Addnumber from './Addnumber';
import Verify from './Verify';
import Reuse from './Reuse';
import Success from './Success';
import Snooze from './Snooze';
import SecondaryButton from '../../../common/SecondaryButton';

const { Title, Text } = Typography;

const Notifications: React.FC = () => {
  const [phoneadd, setPhoneadd] = useState(false);
  const [numModal, setnumModal] = useState(false);
  const [smsModal, setsmsModal] = useState(false);
  const [success, setSuccessModal] = useState(false);
  const [number, setnumber] = useState('');
  const [formattedNumber, setFormattedNumber] = useState('');
  const [snooze, setSnooze] = useState<number[]>([]);
  const [openslack, setOpenslack] = useState(false);
  const [snoozemodal, setSnoozemodal] = useState(false);

  const [items, setItems] = useState([
    {
      key: '1',
      image: Slackicon,
      text1: 'Get your Prep Notes directly on Slack',
      text2: 'Connect Slack',
    },
    {
      key: '2',
      image: Prepicon,
      text1: 'Get text alerts with your Prep Notes',
      text2: 'Add Number',
    },
  ]);

  useEffect(() => {
    let val = number.replace(/\D/g, ''); // Remove non-numeric characters

    if (val.startsWith('1')) {
      val = val.slice(-10);
      // US format
      if (val.length > 3 && val.length <= 6) {
        val = `(${val.slice(0, 3)}) ${val.slice(3)}`;
      } else if (val.length > 6) {
        val = `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6, 10)}`;
      }
    } else {
      val = val.slice(-10);
      // Indian format
      if (val.length > 5) {
        val = `${val.slice(0, 5)} ${val.slice(5, 10)}`;
      }
    }
    setFormattedNumber(val);
  }, [number]);

  const handleclick = async (text2: string) => {
    if (text2 === 'Connect Slack') {
      setOpenslack(true);
      setItems((prevItems) => prevItems.filter((item) => item.text2 !== text2));
    } else {
      setnumModal(true);
    }
  };

  const handlesnooze = () => {
    setSnoozemodal(true);
  };

  useEffect(() => {
    if (smsModal) {
      setItems((prevItems) =>
        prevItems.filter((item) => item.text2 !== 'Add Number')
      );
    }
  }, [smsModal]);

  const isDisabled = (key: string) => snooze.includes(parseInt(key, 10));

  const handleResumeNotifications = () => {
    setSnooze([]); // Clear the snooze array
  };

  return (
    <div className="notification">
      <Row className="notification-container" style={{ marginTop: '30px' }}>
        {items.map((item) => (
          <Col key={item.key} span={24}>
            <Card
              bodyStyle={{
                padding: '12px 16px',
                backgroundColor: '#FEF5EC',
                border: 'none',
              }}
            >
              <Row className="notification-header">
                <div className="notification-content">
                  <img src={item.image} alt="image" />
                  <Text className="notification-text">{item.text1}</Text>
                </div>
                <Button
                  type="link"
                  className="notification-button"
                  onClick={() => handleclick(item.text2)}
                >
                  {item.text2}
                </Button>
              </Row>
            </Card>
          </Col>
        ))}

        {snooze.length > 0 && (
          <Card
            bodyStyle={{
              padding: '12px 16px',
              backgroundColor: '#FEF5EC',
              border: 'none',
            }}
            key={3}
          >
            <Row className="notification-header">
              <div className="notification-content">
                <img src={Group} alt="image" />
                <Text className="notification-text">
                  Your notifications are currently snoozed until Oct 24, 9:30
                  AM.
                </Text>
              </div>
              <Button
                type="link"
                className="notification-button"
                onClick={handleResumeNotifications}
              >
                {'Resume Notification'}
              </Button>
            </Row>
          </Card>
        )}

        <Row className="notification-details">
          {/*Email*/}
          <Col className="notification-col" key={2}>
            <Title level={5}>Email</Title>
            <Col className="notification-item">
              <div className="notification-item-content">
                <Text>
                  Get an email each morning with all of today’s prep notes
                </Text>
                <TimePicker
                  className="time-picker"
                  disabled={isDisabled('2')}
                  format="h:mm A"
                />
              </div>
              <Switch disabled={isDisabled('2')} />
            </Col>
            <Reuse
              mess={
                'Get timely email notifications with prep notes before each meeting'
              }
              key="1"
              disabled={isDisabled('2')}
            />
          </Col>
          <Divider className="divider" />

          {/*Slack*/}
          {openslack && (
            <Col className="notification-slack" key={3}>
              <Col className="notification-col">
                <Title level={5}>Slack</Title>
                <Reuse
                  key="2"
                  mess={
                    'Get your prep notes directly in Slack for quick, in-app reference.'
                  }
                  disabled={isDisabled('3')}
                />
              </Col>
              <Divider className="divider" />
            </Col>
          )}

          {/*Text*/}
          {smsModal && (
            <Col className="notification-text-col" key={4}>
              <Col className="notification-col">
                <div className="notification-header-text">
                  <Title level={5}>Text</Title>
                  <div>
                    <Text className="formatted-number">{formattedNumber}</Text>
                    <Divider type="vertical" />
                    <EditOutlined />
                  </div>
                </div>
                <Reuse
                  key="3"
                  mess={
                    "Receive Text alert with your prep notes when you're on the go"
                  }
                  disabled={isDisabled('4')}
                />
              </Col>
              <Divider className="divider" />
            </Col>
          )}

          {/*In-App*/}
          <Col className="notification-col" key={5}>
            <Title level={5}>In-App</Title>
            <Reuse
              key="4"
              mess={
                'Get real-time alerts directly within the app with prep notes before each meeting'
              }
              disabled={isDisabled('5')}
            />
            <Col className="notification-item">
              <Text>Desktop push notification immediately on your desktop</Text>
              <Switch disabled={isDisabled('5')} />
            </Col>
          </Col>
          <Divider className="divider" />

          {/*Need a break*/}
          <Col className="notification-col">
            <Title level={5}>Need a break?</Title>
            <Col className="notification-item">
              <Text>
                Snooze all alerts for a set time. Your notifications will start
                again automatically when the snooze period ends.
              </Text>
              <SecondaryButton
                text={'Snooze Notifications'}
                className="snooze-button"
                onClick={handlesnooze}
              />
            </Col>
          </Col>
        </Row>
      </Row>

      {numModal && (
        <Addnumber
          onClose={() => setnumModal(false)}
          setPhoneadd={setPhoneadd}
          setnumModal={setnumModal}
          setnumber={setnumber}
        />
      )}
      {phoneadd && (
        <Verify
          onClose={() => setPhoneadd(false)}
          setsmsModal={setsmsModal}
          setPhoneadd={setPhoneadd}
          Number={formattedNumber}
          setSuccessModal={setSuccessModal}
        />
      )}
      {smsModal && success && (
        <Success onClose={() => setSuccessModal(false)} />
      )}
      {snoozemodal && (
        <Snooze onClose={() => setSnoozemodal(false)} setSnooze={setSnooze} />
      )}
    </div>
  );
};

export default Notifications;

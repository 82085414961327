import { WinrateImages } from '../../assets/Images';
import React, { useState } from 'react';
import './login.less';
import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  message,
} from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import {
  fetchUserAttributes,
  signIn,
  signOut,
  signInWithRedirect,
} from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/data';

const Login: React.FC = () => {
  const { authBG, sidePlaceholder, loginLogo, google } = WinrateImages.Auth;
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  type ClientSchema = any;
  const client = generateClient<ClientSchema>({ authMode: 'apiKey' });

  //for google sso
  const handleGoogleSignIn = async () => {
    try {
      // This will trigger the Google OAuth flow using Amplify
      signOut({
        global: false,
      })
        .then(async () => {
          localStorage.clear();
          await signInWithRedirect({
            provider: 'Google',
            customState: 'signIn',
          });
        })
        .catch((err: any) => message.error(err.message));
    } catch (error: any) {
      message.error(error.message);
      console.error('Error during Google Sign In', error);
    }
  };

  //for manual login

  const handleLogin = async () => {
    setLoading(true);
    signOut({
      global: false,
    })
      .then(async () => {
        localStorage.clear();
        const formValue = form.getFieldsValue();
        const emailData = formValue?.email?.toLowerCase();
        const trimedEmail = emailData.trim();

        await signIn({ username: trimedEmail, password: formValue.password })
          .then(async () => {
            const response = await fetchUserAttributes();
            if (response.email) {
              localStorage.setItem('User', response.email);
            }
            const res = await client.queries.integrationStatus({
              email: response.email,
            });
            const usersDetails = JSON.parse(res.data.response);
            if (!usersDetails?.userInfo?.first_name) {
              navigate('/setup-account/0'); // Redirect for setup account flow when user doesn't exist in the database.
            } else if (!usersDetails?.google) {
              navigate('/setup-account/1'); // Redirect to setup account flow when user does not connect to Google
            } else {
              navigate('/'); // Default route
            }
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            if (
              err.message === 'User needs to be authenticated to call this API.'
            ) {
              message.error('user is not confirmed');
              navigate('/verify-email', {
                state: { username: formValue?.email },
              });
            }
            message.error(err.message);
          });
      })
      .catch((err: any) => message.error(err.message));
  };

  return (
    <div className="auth-page">
      <img src={authBG} alt="" className="auth-bg" />
      <Row gutter={16} className="auth-container">
        {/* Left Side: Login Form */}
        <Col xs={24} md={12} className="login-form-container">
          <div className="login-form">
            <img src={loginLogo} alt="Winrate Logo" className="logo mb-20" />

            <Title level={4} className="mb-0">
              Login
            </Title>
            <Text type="secondary">Welcome! Please enter your details.</Text>

            {/* Google Login Button */}
            <Button
              icon={<img src={google} alt="" />}
              className="google-login-btn"
              block
              onClick={handleGoogleSignIn}
            >
              Sign in with Google
            </Button>

            <Divider className="login-divider">or</Divider>

            <Form
              name="login"
              layout="vertical"
              className="login-form-fields"
              requiredMark={false}
              form={form}
              onFinish={() => handleLogin()}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  {
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Please enter a valid email address!',
                  },
                ]}
              >
                <Input placeholder="you@company.com" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                className="mb-0"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
              >
                <Input.Password placeholder="Enter password" />
              </Form.Item>

              <Form.Item className="forgotpassword-link">
                <Button
                  type="text"
                  className="text-secondary"
                  onClick={() => navigate('/forgot-password')}
                >
                  Forgot password
                </Button>
              </Form.Item>

              <Form.Item className="mt-20 mb-10">
                <PrimaryButton
                  text="Login"
                  className="login-btn"
                  loading={loading}
                />
              </Form.Item>
            </Form>
            <Row justify="center" align="middle">
              <Text className="signup-text">Don&apos;t have an account? </Text>
              <Button
                className="signup-link"
                type="text"
                onClick={() => navigate('/signup')}
              >
                {' '}
                Sign Up
              </Button>
            </Row>

            <div className="footer-links">
              <Typography.Text className="mr-5">
                Terms & Conditions
              </Typography.Text>
              <span className="text-secondary">• </span>
              <Typography.Text className="ml-5">Privacy Policy</Typography.Text>
            </div>
            <Text className="signup-text fs-12 mt-10">
              © {new Date().getFullYear()}, Winrate. All Rights Reserved.
            </Text>
          </div>
        </Col>

        {/* Right Side: Image and Icons */}
        <Col xs={0} md={12} className="image-container">
          <div className="image-content">
            <img src={sidePlaceholder} alt="Right Side Illustration" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;

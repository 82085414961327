import { Button, Flex, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import videoLogo from '../../../assets/icons/camera.svg';
import BegginingResearchItem from './BegginingResearchItem';

interface LinkItem {
  id: number;
  text: string;
  isEditing: boolean;
}

interface ResearchItem {
  id: number;
  mainComment: string;
  links: LinkItem[];
}

interface ResearchItemsProps {
  researchItems: ResearchItem[];
}

const ResearchItems: React.FC<ResearchItemsProps> = ({ researchItems }) => {
  const [isResearchModalOpen, setIsResearchModalOpen] = useState(false);
  /* eslint-disable */
  const [researchItemss, setResearchItems] = useState<ResearchItem[]>([]);

  const combinedResearchItems = [...researchItems, ...researchItemss];
  const showResearchModal = () => {
    setIsResearchModalOpen(true);
  };
  const handleCancel = () => {
    setIsResearchModalOpen(false);
  };

  return (
    <div className="custom_research_items mt-24">
      <div>
        <Flex
          align="center"
          justify="space-between"
          style={{ marginBottom: '20px' }}
        >
          <Typography.Title
            level={5}
            className="title"
            style={{ marginBottom: 0 }}
          >
            Your Custom Research
          </Typography.Title>
          <Flex>
            <Button className="settings_action_btn tutorial">
              <img src={videoLogo} />
              Tutorial
            </Button>
            <Button
              className="settings_action_btn"
              style={{ marginLeft: '15px' }}
              onClick={showResearchModal}
            >
              New Research
            </Button>
          </Flex>
        </Flex>
        {combinedResearchItems.map((item) => (
          <div key={item.id} className="research_list_item">
            <div key={item.id} className="research_list_item_inner">
              <h3>{item.mainComment}</h3>
              <ul>
                {item.links.map((link) => (
                  <li key={link.id}>{link.text}</li>
                ))}
              </ul>
            </div>
            <Flex
              className="actions"
              align="center"
              justify="end"
              style={{ width: '100%' }}
            >
              <Button>Edit</Button>
              <Button
                style={{
                  marginLeft: '8px',
                }}
              >
                Remove
              </Button>
            </Flex>
          </div>
        ))}
      </div>
      <Modal
        open={isResearchModalOpen}
        title={null} // Remove the header
        closable={false}
        className="modal_research"
        centered
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          ,
        ]}
      >
        <BegginingResearchItem
          setResearchItems={setResearchItems}
          isModal={true}
          setIsResearchModalOpen={setIsResearchModalOpen}
        />
      </Modal>
    </div>
  );
};

export default ResearchItems;

import { Button, Col, Row } from 'antd';
import React, { useState } from 'react';
import Welcome from './Welcome';
import Research from './Research';
import ImagePopup from './ImagePopup';
import Sailor from '../../../assets/icons/Sailor.svg';

const CustomResearch: React.FC = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const showFirstResearchModal = () => {
    setIsPopupVisible(true);
  };

  const closePopup = () => {
    setIsPopupVisible(false);
  };
  return (
    <div className="customResearch">
      <Row>
        <Col span={24}>
          <div className="custom_research_item">
            <Welcome />
            <Research />
            <Button
              onClick={showFirstResearchModal}
              style={{ marginTop: '20px' }}
            >
              Custom Research Modal
            </Button>
            <ImagePopup
              img={Sailor}
              title={
                <>
                  Congratulations on Your{' '}
                  <span className="team-text">First</span> Research! 🎉
                </>
              }
              desc="We re diving deep into the questions you ve asked. Your questions are
          the compass guiding us to meaningful discoveries - lets dive in!"
              isOpen={isPopupVisible} // Pass visibility state
              onClose={closePopup} // Pass close handler
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomResearch;

import { Avatar, Button, Col, Flex, List, Row } from 'antd';
import React from 'react';
import slackLogo from '../../../assets/icons/Slack.svg';
import hubspotLogo from '../../../assets/icons/hubspot.svg';
import calenderLogo from '../../../assets/icons/Calender.svg';
import checkLogo from '../../../assets/icons/check.svg';
import salesforceLogo from '../../../assets/icons/Salesforce.svg';

const Integrations: React.FC = () => {
  return (
    <div className="integrations">
      <Row>
        <Col span={24}>
          <div className="integration_item">
            <List itemLayout="horizontal">
              <List.Item
                extra={
                  <Button
                    type="primary"
                    className="settings_action_btn"
                    title="Connect"
                  >
                    Connect
                  </Button>
                }
              >
                <List.Item.Meta
                  avatar={<Avatar src={slackLogo} />}
                  title="Slack"
                  description={
                    <div>
                      Get instant notifications about important updates and
                      meeting reminders directly in Slack.{' '}
                      <span>Why Connect Slack?</span>
                    </div>
                  }
                />
              </List.Item>
              <List.Item
                extra={
                  <Button
                    type="primary"
                    className="settings_action_btn"
                    title="Connect"
                  >
                    Connect
                  </Button>
                }
              >
                <List.Item.Meta
                  avatar={<Avatar src={hubspotLogo} />}
                  title="HubSpot"
                  description={
                    <div>
                      Sync up-to-date lead activities and engagement history to
                      enrich your prep notes. <span>Why Connect Hubspot?</span>
                    </div>
                  }
                />
              </List.Item>
              <List.Item
                extra={
                  <Flex align="center" className="connected">
                    <img src={checkLogo} alt="Checked" />
                    <span>Connected</span>
                  </Flex>
                }
              >
                <List.Item.Meta
                  avatar={<Avatar src={calenderLogo} />}
                  title="Google Calender"
                />
              </List.Item>
              <List.Item
                extra={
                  <Button
                    className="settings_action_btn disconnect"
                    title="Disconnect"
                  >
                    Disconnect
                  </Button>
                }
              >
                <List.Item.Meta
                  avatar={<Avatar src={salesforceLogo} />}
                  title="Salesforce"
                />
              </List.Item>
            </List>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Integrations;

import { Avatar, Button, Dropdown, message, Row, Typography } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { WinrateImages } from '../assets/Images';
import { UserOutlined, SettingOutlined } from '@ant-design/icons';
import '../App.less';
// import SearchComponent from '../common/SearchComponent';
import type { MenuProps } from 'antd';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  setCollapsed?: Dispatch<SetStateAction<boolean>>;
  collapsed?: boolean;
}

const HeaderComponent: React.FC<HeaderProps> = ({
  setCollapsed,
  collapsed,
}: any) => {
  const { unfoldIcon, foldIcon, bellIcon, helpIcon } = WinrateImages.Icons;
  const navigate = useNavigate();
  const handleSignOut = () => {
    signOut({
      global: false,
    })
      .then(() => {
        localStorage.clear();
        navigate('/login');
      })
      .catch((err: any) => {
        message.error(err.message);
      });
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Typography.Text>Logout</Typography.Text>,
      onClick: () => handleSignOut(),
    },
  ];
  return (
    <Row justify="space-between" align="middle">
      <Button
        type="text"
        icon={
          collapsed ? (
            <img src={foldIcon} alt="" />
          ) : (
            <img src={unfoldIcon} alt="" />
          )
        }
        onClick={() => setCollapsed(!collapsed)}
        className="collapse-btn"
      />
      <Row>
        {/* <SearchComponent placeholder="Search Winrate" /> */}
        <Button type="text" className="pr-0">
          <img src={helpIcon} alt="" />
        </Button>
        <Button type="text" className="pr-0">
          <SettingOutlined className="fs-20" />
        </Button>
        <Button type="text" className="bell-icon mr-20">
          <img src={bellIcon} alt="" />
        </Button>
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          className="user-dropdown"
        >
          <span>
            <Avatar className="mb-5" icon={<UserOutlined />}></Avatar>
          </span>
        </Dropdown>
      </Row>
    </Row>
  );
};

export default HeaderComponent;

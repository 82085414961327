/* eslint-disable react/prop-types */
import { Col, Modal, Row, Typography, Button, ModalProps, Form } from 'antd';
import { useEffect, useState } from 'react';
import './notification.less';
import PhoneInput from './Phoneinput';

const { Title, Text } = Typography;

interface Inummodal extends ModalProps {
  onClose: () => void;
  setPhoneadd: (value: boolean) => void;
  setnumModal: (value: boolean) => void;
  setnumber: (value: string) => void;
}

const Addnumber: React.FC<Inummodal> = ({
  onClose,
  setPhoneadd,
  setnumModal,
  setnumber,
}) => {
  const [form] = Form.useForm();
  const [isVerifyButtonDisabled, setIsVerifyButtonDisabled] = useState(true);

  const handleCancel = () => {
    onClose();
  };

  const handleConfirmation = () => {
    const phoneNumber = form.getFieldValue('number');
    setnumber(phoneNumber);
    setPhoneadd(true);
    setnumModal(false);
  };

  const handlePhoneChange = (value: string) => {
    const phoneNumber = value ? value.replace(/\D/g, '') : '';
    form.setFieldsValue({ number: phoneNumber });
    if (phoneNumber[0] === '1') {
      setIsVerifyButtonDisabled(phoneNumber.length !== 11);
    } else {
      setIsVerifyButtonDisabled(phoneNumber.length !== 12);
    }
  };

  useEffect(() => {
    const phoneNumber = form.getFieldValue('number');
    if (phoneNumber) {
      setnumber(phoneNumber);
    }
  }, [form, setnumber]);

  return (
    <Modal
      footer={null}
      width={600}
      centered
      open={true}
      onCancel={handleCancel}
      className="addnumber-modal" // Custom class for modal styling
    >
      <Row className="addnumber-content">
        <Row className="addnumber-header">
          <Title level={4}>Add Your Number for SMS</Title>
          <Text>
            Enter your mobile number to receive timely SMS reminders and updates
            before each meeting.
          </Text>
          <Col>
            <Form form={form} layout="vertical" onFinish={handleConfirmation}>
              <Form.Item
                label="Mobile Number"
                name="number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your mobile number!',
                  },
                ]}
              >
                <PhoneInput
                  name="number"
                  label="Mobile Number"
                  placeholder="Enter your phone number"
                  value={form.getFieldValue('number')}
                  handleChange={handlePhoneChange}
                />
              </Form.Item>
              <Col className="addnumber-actions">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isVerifyButtonDisabled}
                  style={{
                    width: '100%',
                  }}
                  className="button"
                >
                  Verify
                </Button>
                <Button type="text" className="branding-color">
                  I&apos;ll do it later {/* Escaped apostrophe */}
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

export default Addnumber;

import {
  CloseOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusCircleFilled,
} from '@ant-design/icons';
import { Button, Col, Row, Space, Tooltip, Typography } from 'antd';
import { Input, message } from 'antd';
import React, { useState } from 'react';

const { TextArea } = Input;

interface LinkItem {
  id: number;
  text: string;
  isEditing: boolean;
}

interface ResearchItem {
  id: number;
  mainComment: string;
  links: LinkItem[];
}

interface BegginingResearchItemProps {
  setResearchItems: React.Dispatch<React.SetStateAction<ResearchItem[]>>;
  isModal?: boolean;
  setIsResearchModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const BegginingResearchItem: React.FC<BegginingResearchItemProps> = ({
  setResearchItems,
  isModal,
  setIsResearchModalOpen,
}) => {
  const [mainComment, setMainComment] = useState<string>('');
  const [links, setLinks] = useState<LinkItem[]>([]);

  const addLink = () => {
    setLinks([...links, { id: Date.now(), text: '', isEditing: true }]);
  };

  const deleteLink = (id: number) => {
    setLinks(links.filter((link) => link.id !== id));
  };

  const toggleEditLink = (id: number) => {
    setLinks(
      links.map((link) =>
        link.id === id ? { ...link, isEditing: !link.isEditing } : link
      )
    );
  };

  const updateLinkText = (id: number, value: string) => {
    setLinks(
      links.map((link) => (link.id === id ? { ...link, text: value } : link))
    );
  };

  const handleBlur = (id: number) => {
    setLinks(
      links.map((link) =>
        link.id === id ? { ...link, isEditing: false } : link
      )
    );
  };

  const createResearchItem = () => {
    if (!mainComment) {
      message.error('Please Add Comments To Textarea.');
      return;
    }
    const newResearchItem: ResearchItem = {
      id: Date.now(),
      mainComment,
      links: links.map((link) => ({
        ...link,
        id: Date.now() + Math.random(),
      })),
    };

    setResearchItems((prev) => [...prev, newResearchItem]);
    if (setIsResearchModalOpen) {
      setIsResearchModalOpen(false);
    }
    setMainComment('');
    setLinks([]);
  };

  return (
    <Row>
      <Col span={24}>
        <div className="custom_research_add_item title-font">
          {!isModal ? (
            <>
              <Typography.Title level={5} className="title">
                Start Your Custom Research
              </Typography.Title>
              <Typography.Paragraph className="description">
                Enter a question or topic, guiding Winrate AI on what you’re
                looking to explore
              </Typography.Paragraph>
            </>
          ) : (
            <Typography.Title level={5} className="title">
              New Research
            </Typography.Title>
          )}
        </div>
        <div className="custom_research_beginning_item">
          <TextArea
            placeholder="Ask anything and we’ll show magic in Prep Note"
            style={{ height: 70, resize: 'none' }}
            value={mainComment}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setMainComment(e.target.value)
            }
          />
          {links.map((link) => (
            <Space key={link.id} className="add_link_item">
              <Input
                value={link.text}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  updateLinkText(link.id, e.target.value)
                }
                placeholder="Get automated targeted insights by pasting any link"
                className="input_link"
                onBlur={() => handleBlur(link.id)}
                disabled={!link.isEditing}
              />

              {link.text && !link.isEditing ? (
                <EditOutlined
                  className="edit_link"
                  onClick={() => toggleEditLink(link.id)}
                />
              ) : null}

              <CloseOutlined
                onClick={() => deleteLink(link.id)}
                className="delete_link"
              />
            </Space>
          ))}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '12px',
              padding: '5px 0',
            }}
          >
            <Typography.Title
              level={5}
              className="title"
              onClick={addLink}
              style={{ cursor: 'pointer' }}
            >
              <PlusCircleFilled
                style={{
                  color: '#0b8751',
                  marginRight: '8px',
                  cursor: 'pointer',
                }}
              />
              Add Link
              <span>
                <Tooltip
                  placement="bottomLeft"
                  title="Get automated targeted insights
by adding any link"
                >
                  <InfoCircleOutlined
                    style={{
                      color: '#141414',
                      marginLeft: '8px',
                      fontSize: '12px',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              </span>
            </Typography.Title>
            {!mainComment && links.length === 0 ? (
              <Button className="disabled" disabled>
                Save Research
              </Button>
            ) : (
              <Button
                className="settings_action_btn"
                onClick={createResearchItem}
              >
                Save Research
              </Button>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default BegginingResearchItem;

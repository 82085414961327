import { ApiError, get, post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

export const doGet = async (path: string): Promise<any> => {
  console.log('doGet fetchSession');
  const session = await fetchAuthSession();
  const authToken = session.tokens?.idToken || '';
  console.log('doGet', authToken.toString());

  try {
    const restOperation = get({
      apiName: 'winrateRestApi',
      path: `api/${path}`,
      options: {
        headers: {
          Authorization: authToken.toString(),
        },
      },
    });
    const { body } = await restOperation.response;
    const json = await body.json();
    return json;
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.response) {
        const { statusCode, body } = error.response;
        console.error(
          `Received ${statusCode} error response with payload: ${body}`
        );
      } else {
        console.error('An unknown api error occurred:', error);
      }
      // Handle API errors not caused by HTTP response.
    } else {
      // Handle other errors, such as network issues.
      console.error('An error occurred:', error);
    }
    return null;
  }
};

const doPost = async (path: string, payload: any): Promise<any> => {
  console.log('doPost fetchSession');
  const session = await fetchAuthSession();
  const authToken = session.tokens?.idToken || '';
  console.log('doPost with payload:', payload, authToken.toString());

  try {
    const restOperation = post({
      apiName: 'winrateRestApi',
      path: `api/${path}`,
      options: {
        headers: {
          Authorization: authToken.toString(),
        },
        body: payload,
      },
    });
    const { body } = await restOperation.response;
    const json = await body.json();
    return json;
  } catch (error) {
    if (error instanceof ApiError) {
      if (error.response) {
        const { statusCode, body } = error.response;
        console.error(
          `Received ${statusCode} error response with payload: ${body}`
        );
      } else {
        console.error('An unknown api error occurred:', error);
      }
      // Handle API errors not caused by HTTP response.
    } else {
      // Handle other errors, such as network issues.
      console.error('An error occurred:', error);
    }
    return null;
  }
};

export const getMeetingNotes = async (summaryId: string): Promise<any> => {
  return await doGet(`events/${summaryId}`);
};

export const createMeetingNotes = async (payload: any): Promise<any> => {
  return await doPost('events', payload);
};

import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Skeleton,
  Divider,
  Typography,
  Row,
  Col,
  Button,
  Breadcrumb,
  message,
} from 'antd';
import { WinrateImages } from '../assets/Images';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { getMeetingNotes } from '../sdk/Events';
import './MeetingNotes.less';

const { Paragraph } = Typography;
const MAX_ATTEMPTS = 10;

const Skeletons = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Skeleton active={true} />
      <Skeleton active={true} />
    </div>
  );
};

function formatMeetingDate(startTime: string, endTime: string): any {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  const formattedDate = dateFormatter.format(startDate);
  const formattedStartTime = timeFormatter.format(startDate);
  const formattedEndTime = timeFormatter.format(endDate);

  const day = startDate.getDate();
  const daySuffix =
    day % 10 === 1 && day !== 11
      ? 'st'
      : day % 10 === 2 && day !== 12
        ? 'nd'
        : day % 10 === 3 && day !== 13
          ? 'rd'
          : 'th';

  return (
    <>
      {formattedDate.replace(/\d+/, day + daySuffix)}{' '}
      <span className="lil-divider">|</span> {formattedStartTime} -{' '}
      {formattedEndTime}
    </>
  );
}

type EnrichedAttendees = {
  attendee: string;
  contact: {
    linkedin_url: string;
    full_name: string;
    occupation: string;
    headline: string;
    previous_role: {
      title: string;
      company: string;
    };
  };
  meetings: number;
  emails: {
    snippetSummary: string;
    totalCount: number;
  };
};

type CompanyProfile = {
  name: string;
  domain: string;
  website: string;
  linkedin_url: string;
  description: string;
  founded_year: number;
  size: [number, number];
};

type CompanyNews = {
  title: string;
  url: string;
  content: string;
};

type Company = {
  profile: CompanyProfile;
  news: CompanyNews[];
};

type AINews = {
  headline: string;
  source: string;
  summary: string;
  url: string;
};

type MeetingNote = {
  event: any;
  summary: string;
  startTime: string;
  endTime: string;
  company: Company;
  attendees: string[];
  enrichedExternalAttendees: EnrichedAttendees[];
  setupContext: string;
  suggestedQuestions: string;
  customResearch: string[];
  research: any[];
  aiSummary: any;
  crmNotes: any;
};

type Events = {
  updated_at: string;
};

const NewsCard = ({ news }: { news: AINews }) => {
  if (!news.url) return null;
  const newsDomain = new URL(news.url).hostname.replace('www.', '');
  return (
    <div
      style={{
        flex: '0 0 auto',
        cursor: 'pointer',
      }}
      onClick={() => window.open(news.url, '_blank')}
    >
      <div className="shadow-card news-card">
        <div className="news-summary">{news.headline}</div>
        <div className="news-source">
          <img src={`https://logo.clearbit.com/${newsDomain}`} width="16" />
          {news.source}
        </div>
      </div>
    </div>
  );
};

const AboutSection = ({
  company,
  about,
  news,
  crmCompany,
  crmId,
}: {
  company: Company;
  about: any;
  news: AINews[];
  crmCompany?: any;
  crmId?: string;
}) => {
  return (
    <div className="note-about">
      <h2 className="section-title">
        About {company?.profile?.name || company?.profile?.domain}
      </h2>
      <div className="note-about-stats">
        <div className="align-flex">
          <img
            src={`https://logo.clearbit.com/${company?.profile?.domain}`}
            alt="Company Logo"
            width="18"
          />
          <div>
            <a
              style={{ color: 'inherit' }}
              target="_blank"
              rel="noopener noreferrer"
              href={company?.profile?.website}
            >
              {company?.profile?.domain}
            </a>
          </div>
        </div>
        {company?.profile?.founded_year && (
          <>
            &middot;
            <div>Founded: {company?.profile?.founded_year}</div>
          </>
        )}
        {company?.profile?.size && (
          <>
            &middot;
            <div>
              # of Employees:{' '}
              <span>
                {company?.profile?.size[0]}-{company?.profile?.size[1]}
              </span>
            </div>
          </>
        )}
        &middot;
        <a
          href={company?.profile?.linkedin_url}
          target="_blank"
          rel="noreferrer"
          style={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
            color: '#0072b1',
          }}
        >
          <img
            src={`https://logo.clearbit.com/linkedin.com`}
            alt="Linkedin Logo"
            width="18"
          />
          LinkedIn
        </a>
        {crmCompany && crmId && (
          <>
            &middot;
            <a
              href={`https://app.hubspot.com/contacts/${crmId}/companies/${crmCompany['hs_object_id']}`}
              target="_blank"
              rel="noreferrer"
              style={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center',
                color: '#0072b1',
              }}
            >
              <img
                src={WinrateImages.Icons.hubspotIcon}
                alt="Hubspot logo"
                width="18px"
              />
              Hubspot
            </a>
          </>
        )}
      </div>

      <div className="note-about-description">
        <Paragraph
          className="company-description"
          ellipsis={{
            rows: 3,
            expandable: true,
            symbol: 'more',
          }}
          title={company?.profile?.description}
        >
          {company?.profile?.description}
        </Paragraph>
        <ul className="to-summarize">
          {about?.description &&
            about?.description?.toLowerCase() !== 'none' && (
              <li>
                <strong>What do they do:</strong>
                <span> {about?.description}</span>
              </li>
            )}
          {about?.revenueModel &&
            about?.revenueModel?.toLowerCase() !== 'none' && (
              <li>
                <strong>How do they make money:</strong>
                <span> {about?.revenueModel}</span>
              </li>
            )}
          {about?.totalFundraising &&
            about?.totalFundraising?.toLowerCase() !== 'none' && (
              <li>
                <strong>Fundraising:</strong>
                <span> {about?.totalFundraising}</span>
              </li>
            )}
          {about?.notableInvestors && about?.notableInvestors?.length > 0 && (
            <li>
              <strong>Investors:</strong>
              <span> {about?.notableInvestors?.join(', ')}</span>
            </li>
          )}
        </ul>
      </div>

      <div className="note-about-news">
        <h3>News</h3>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            gap: '10px',
            overflowX: 'auto',
            padding: '10px 2px',
            scrollbarWidth: 'thin',
            scrollbarColor: '#DDD rgba(0,0,0,.02)',
          }}
        >
          {news.map((newss) => (
            <NewsCard key={newss.url} news={newss} />
          ))}
        </div>
      </div>
    </div>
  );
};

const AttendeeSection = ({
  attendees,
  contacts,
  crmId,
}: {
  attendees: EnrichedAttendees[];
  contacts: any;
  crmId?: string;
}) => {
  return (
    <div
      className="note-attendees"
      style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
    >
      <h2 className="section-title">Who is coming to the call</h2>
      <div
        style={{
          display: 'grid',
          gap: '10px',
          gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
        }}
      >
        {attendees
          .filter((a) => !!a.contact.full_name)
          .map((attendee, i) => (
            <div key={`attendee-${i}`} className="attendee-card shadow-card">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={attendee.contact.linkedin_url}
              >
                <div className="align-flex">
                  <div className="contact-name">
                    {attendee.contact.full_name}
                  </div>
                  <img
                    src="https://logo.clearbit.com/linkedin.com"
                    width="18"
                  />
                </div>
              </a>
              <div className="contact-info">
                <p>
                  {attendee.contact.occupation}
                  {attendee.contact.headline !==
                    attendee.contact.occupation && (
                    <>
                      <br />
                      <span>{attendee.contact.headline}</span>
                    </>
                  )}
                  {attendee?.contact?.previous_role?.title && (
                    <>
                      .{' '}
                      <span>
                        Previously {attendee?.contact?.previous_role?.title} at{' '}
                        {attendee?.contact?.previous_role?.company}
                      </span>
                    </>
                  )}
                </p>
              </div>
              {contacts[attendee.attendee] && (
                <div
                  style={{
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center',
                    padding: '5px 0px',
                  }}
                >
                  <img
                    src={WinrateImages.Icons.hubspotIcon}
                    alt="Hubspot logo"
                    width="18px"
                  />
                  <a
                    style={{ color: '#0072b1', fontWeight: '500' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://app.hubspot.com/contacts/${crmId}/contact/${contacts[attendee.attendee]['hs_object_id']}`}
                  >
                    View in Hubspot
                  </a>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
type SectionName =
  | 'About'
  | 'Who is coming to the call'
  | 'Get caught up'
  | 'Your Research';

export const MeetingNotes = () => {
  // Create refs for each section
  const aboutRef = useRef(null);
  const attendeesRef = useRef(null);
  const caughtUpRef = useRef(null);
  const researchRef = useRef(null);
  const { id = '' } = useParams();
  const [meetingNotes, setMeetingNotes] = useState({} as MeetingNote);
  const [updatedTime, setUpdatedTime] = useState({} as Events);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedSection, setSelectedSection] = useState<SectionName | string>(
    'About'
  );
  const [noteUpdated, setNoteUpdated] = useState(false);
  const [initialUpdatedAt, setInitialUpdatedAt] = useState<string | null>(null);
  const [showUpdatedTime, setShowUpdatedTime] = useState(false);
  // Updated to specify the type of `section`
  const handleScrollToSection = (section: SectionName) => {
    const sectionRefs: Record<SectionName, React.RefObject<HTMLDivElement>> = {
      About: aboutRef,
      'Who is coming to the call': attendeesRef,
      'Get caught up': caughtUpRef,
      'Your Research': researchRef,
    };

    const selectedRef = sectionRefs[section];
    if (selectedRef?.current) {
      selectedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    setSelectedSection(section);
  };

  const fetchNotes = async (attempt = 0) => {
    try {
      const { data } = await getMeetingNotes(id);

      if (
        !data?.summary?.company &&
        ['CREATED', 'IN PROGRESS'].includes(data?.event?.status) &&
        attempt < MAX_ATTEMPTS
      ) {
        setTimeout(() => {
          if (attempt < MAX_ATTEMPTS) {
            fetchNotes(attempt + 1);
          }
        }, 3000 * attempt);
        return;
      } else if (
        !data?.summary?.company &&
        data?.event?.status !== 'COMPLETED'
      ) {
        console.error('Error fetching notes:', data?.event?.message);
        setFailed(true);
        setLoading(false);
        return;
      }
      if (!initialUpdatedAt) {
        // Set initial updatedAt on the first fetch
        setInitialUpdatedAt(data?.event?.updated_at);
      }
      setUpdatedTime(data?.event);
      setShowUpdatedTime(initialUpdatedAt !== data?.event?.updated_at);
      const mtngnotes = data?.summary;
      if (
        !mtngnotes?.company?.profile?.domain &&
        mtngnotes?.company?.profile?.website
      ) {
        const url = new URL(mtngnotes?.company?.profile?.website);
        mtngnotes.company.profile.domain = url.hostname.replace('www.', '');
      }
      setMeetingNotes(mtngnotes || {});
      setLoading(false);
      if (noteUpdated) {
        message.success('Prep note updated successfully');
      }
    } catch (error) {
      // setTimeout and fetch again if attempts is less than 5
      setTimeout(() => {
        if (attempt < 5) {
          fetchNotes(attempt + 1);
        } else {
          console.error('Error fetching notes:', error);
        }
      }, 2000);
      console.error('Error fetching notes:', error);
    }
  };

  useEffect(() => {
    fetchNotes(1);
  }, [id]);

  function timeAgo(timestamp: string): string {
    const now = new Date();
    const time = new Date(timestamp);
    const diffInMs = Math.abs(now.getTime() - time.getTime());

    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const days = Math.floor(diffInMinutes / (60 * 24));
    const hours = Math.floor((diffInMinutes % (60 * 24)) / 60);
    const minutes = diffInMinutes % 60;

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ${hours} hour${hours > 1 ? 's' : ''}`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  }

  return (
    <Row gutter={[16, 16]}>
      <Col
        xs={24}
        sm={8}
        md={6}
        lg={5}
        xxl={4}
        className="menu-section summary-points-menu"
      >
        <Breadcrumb separator=">" className="mb-20">
          <Breadcrumb.Item>
            <Link to="/">Dashboard</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Prep note</Breadcrumb.Item>
        </Breadcrumb>
        {showUpdatedTime && (
          <Button
            onClick={() => {
              fetchNotes(1);
              setNoteUpdated(true);
            }}
            className="p-0"
            type="text"
            icon={<img src={WinrateImages.EventIcons.refreshIcon} />}
          >
            <Typography.Text>
              Updated {timeAgo(updatedTime?.updated_at)} ago
            </Typography.Text>
          </Button>
        )}
        <div className="summary-title">
          <Typography.Text
            className={
              selectedSection === 'About' ? 'fs-16 fw-600' : 'summary-points'
            }
            onClick={() => handleScrollToSection('About')}
          >
            About{' '}
            {meetingNotes?.company?.profile?.name ||
              meetingNotes?.company?.profile?.domain}
          </Typography.Text>
          <Typography.Text
            className={
              selectedSection === 'Who is coming to the call'
                ? 'fs-16 fw-600'
                : 'summary-points'
            }
            onClick={() => handleScrollToSection('Who is coming to the call')}
          >
            Who is coming to the call
          </Typography.Text>
          <Typography.Text
            className={
              selectedSection === 'Get caught up'
                ? 'fs-16 fw-600'
                : 'summary-points'
            }
            onClick={() => handleScrollToSection('Get caught up')}
          >
            Get caught up
          </Typography.Text>
          <Typography.Text
            onClick={() => handleScrollToSection('Your Research')}
            className={
              selectedSection === 'Your Research'
                ? 'fs-16 fw-600'
                : 'summary-points'
            }
          >
            Your Research
          </Typography.Text>
        </div>
      </Col>
      <Col xs={24} sm={16} md={18} lg={19} xxl={20} className="content-section">
        {loading && <Skeletons />}
        {!loading && failed && (
          <div>
            <h1>Failed to fetch meeting notes</h1>
            <p>
              There was an error fetching the meeting notes. Please try again
              later.
            </p>
          </div>
        )}
        {!loading && !failed && meetingNotes && meetingNotes?.summary && (
          <div>
            <div className="note-title-section">
              <div>
                <h1 className="meeting-title">{meetingNotes?.summary}</h1>
                <p className="meeting-time">
                  {formatMeetingDate(
                    meetingNotes?.startTime,
                    meetingNotes?.endTime
                  )}
                </p>
              </div>
              <div>
                <img
                  src={`https://logo.clearbit.com/${meetingNotes?.company?.profile?.domain}`}
                  alt="Company Logo"
                  width="48"
                />
              </div>
            </div>
            <Divider />
            <div className="note-body-section" ref={aboutRef}>
              <AboutSection
                company={meetingNotes?.company}
                about={meetingNotes?.aiSummary?.generated_schema?.about || {}}
                news={meetingNotes?.aiSummary?.generated_schema?.news || []}
                crmCompany={
                  meetingNotes?.crmNotes?.companies?.[
                    meetingNotes?.event?.domain
                  ]
                }
                crmId={meetingNotes?.crmNotes?.portalId}
              />
              <Divider />
              <div ref={attendeesRef}>
                <AttendeeSection
                  attendees={meetingNotes?.enrichedExternalAttendees}
                  contacts={meetingNotes?.crmNotes?.contacts || {}}
                  crmId={meetingNotes?.crmNotes?.portalId}
                />
              </div>

              <Divider />
              <div
                ref={caughtUpRef}
                className="note-context"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <h2 className="section-title">Get caught up</h2>
                <div>
                  {meetingNotes?.aiSummary?.generated_schema?.callSetup
                    ?.summary || ''}
                </div>
                {meetingNotes?.aiSummary?.generated_schema?.emailNotes && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '10px',
                      alignItems: 'flex-start',
                    }}
                  >
                    <img
                      src={WinrateImages.Icons.gmailIcon}
                      alt="GMail logo"
                      width="20px"
                      style={{ padding: '4px 0px' }}
                    />
                    <div>
                      {meetingNotes?.aiSummary?.generated_schema?.emailNotes}
                    </div>
                  </div>
                )}
                {meetingNotes?.crmNotes?.provider &&
                  meetingNotes?.aiSummary?.generated_schema?.crmNotes && (
                    <div
                      style={{
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'flex-start',
                      }}
                    >
                      <img
                        src={WinrateImages.Icons.hubspotIcon}
                        alt="Hubspot logo"
                        width="20px"
                        style={{ padding: '4px 0px' }}
                      />
                      <div>
                        {meetingNotes?.aiSummary?.generated_schema?.crmNotes}
                      </div>
                    </div>
                  )}
              </div>

              {meetingNotes?.customResearch &&
                meetingNotes?.customResearch.length > 0 && (
                  <>
                    <Divider />
                    <div className="note-research" ref={researchRef}>
                      <h2 className="section-title">Your Research</h2>
                      <div className="note-research-notes">
                        {meetingNotes?.customResearch.map((research, i) => (
                          <div key={`research-${i}`}>
                            <Markdown remarkPlugins={[remarkGfm]}>
                              {research}
                            </Markdown>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}

              {meetingNotes?.research && meetingNotes?.research.length > 0 && (
                <>
                  <Divider />
                  <div className="note-research" ref={researchRef}>
                    <h2 className="section-title">Your Research</h2>
                    <div className="note-research-notes">
                      {meetingNotes?.research.map((research, i) => (
                        <div key={`research-${i}`}>
                          <Markdown remarkPlugins={[remarkGfm]}>
                            {research.message}
                          </Markdown>
                          {research.citations && (
                            <div className="note-research-citations">
                              <h3>Sources</h3>
                              {research.citations.map(
                                (citation: any, j: number) => (
                                  <div key={`citation-${j}`}>
                                    <a
                                      href={citation}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {citation}
                                    </a>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {/* <Divider />
            <div className="note-questions">
              <h2 className="section-title">Suggested questions</h2>
              <ol className="note-questions-list">
                {meetingNotes?.aiSummary?.generated_schema
                  ?.suggestedQuestions &&
                  (
                    meetingNotes?.aiSummary?.generated_schema
                      ?.suggestedQuestions || []
                  ).map((question: string, i: number) => (
                    <li key={`question-${i}`}>{question}</li>
                  ))}
              </ol>
            </div> */}
            </div>
          </div>
        )}
        {/* <pre>{JSON.stringify(meetingNotes, undefined, 2)}</pre> */}
      </Col>
    </Row>
  );
};

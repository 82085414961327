import App from './App';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import './index.css';

import './index.css';
import { Amplify } from 'aws-amplify';
import outputs from './amplify_outputs.json';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

//const isStaging = window.location.hostname.includes('staging');
const isDev = window.location.hostname.includes('dev');
const [localRedirectSignIn, devRedirectSignIn, stagingRedirectSignIn] =
  outputs.auth.oauth.redirect_sign_in_uri;

const [localRedirectSignOut, devRedirectSignOut, stagingRedirectSignOut] =
  outputs.auth.oauth.redirect_sign_out_uri;

const redirectSignIn = isLocalhost
  ? localRedirectSignIn
  : isDev
    ? devRedirectSignIn
    : stagingRedirectSignIn;
const redirectSignOut = isLocalhost
  ? localRedirectSignOut
  : isDev
    ? devRedirectSignOut
    : stagingRedirectSignOut;

Amplify.configure(outputs);

const existingConfig = Amplify.getConfig();

console.log('existingConfig', existingConfig);

Amplify.configure({
  ...existingConfig,
  auth: {
    ...outputs.auth,
    oauth: {
      ...outputs.auth.oauth,
      redirect_sign_in_uri: [redirectSignIn],
      redirect_sign_out_uri: [redirectSignOut],
    },
  },
  API: {
    ...existingConfig.API,
    REST: { ...existingConfig?.API?.REST, ...outputs.custom.API },
  },
});

console.log('Updated config', Amplify.getConfig());

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { WinrateImages } from '../../assets/Images';
import React, { useState } from 'react';
import { Button, Col, Form, Input, message, Row, Typography } from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { autoSignIn, confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';

const VerifyEmail: React.FC = () => {
  const { authBG, loginLogo, emailIcon } = WinrateImages.Auth;
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const email = location?.state?.username;

  //Function to handle confirm SignUp
  const handleConfirmSignUp = async (e: { code: string }) => {
    try {
      setLoading(true);
      const username = email ? email : localStorage.getItem('User');
      if (username && e.code) {
        await confirmSignUp({ username: username, confirmationCode: e.code })
          .then(async () => {
            await autoSignIn();
            Navigate('/setup-account/0');
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            message.error(error.message);
          });
      } else {
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  //Function to handle resend Code
  const handleResendCode = async () => {
    setLoader(true);
    try {
      const username = email ? email : localStorage.getItem('User');
      await resendSignUpCode({ username: username });
      message.success('A new confirmation code has been sent.');
      setLoader(false);
    } catch (e) {
      const errorMessage = (e as Error).message || 'An unknown error occurred';
      message.error(errorMessage);
      setLoader(false);
    }
  };
  return (
    <div className="auth-page">
      <img src={authBG} alt="" className="auth-bg" />
      <div className="onboarding-container email-form-container">
        <img src={loginLogo} alt="Winrate Logo" className="logo mb-20" />
        <div className="mt-24">
          <img src={emailIcon} alt="" className="logo" />
        </div>
        <Typography.Title level={4} className="mt-24 signup-text">
          Verify Your Email
        </Typography.Title>
        <Typography.Text className="signup-text text-secondary">
          A verification code has been sent to{'  '}
          <span className="text-primary fw-500 ml-5">
            {' '}
            {email ? email : localStorage.getItem('User')}
          </span>
        </Typography.Text>
        <Typography.Text className="signup-text text-secondary">
          Please enter the code to complete your sign-up.
        </Typography.Text>
        <Row className="signup-text mt-24">
          <Col span={8}>
            <Form
              name="login"
              layout="vertical"
              className="login-form-fields"
              requiredMark={false}
              form={form}
              onFinish={(e) => handleConfirmSignUp(e)}
            >
              <Form.Item
                label="Verification code"
                name="code"
                rules={[{ required: true, message: 'Please input your code!' }]}
              >
                <Input placeholder="6-digit verification code" />
              </Form.Item>

              <Form.Item className="mt-20 mb-10">
                <PrimaryButton
                  text="Verify"
                  className="login-btn"
                  loading={loading}
                />
              </Form.Item>
              <Form.Item className="signup-text">
                <Button
                  type="text"
                  className="branding-color"
                  onClick={handleResendCode}
                  loading={loader}
                >
                  Resend email
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Row justify="space-between" className="verify-email-footer">
          <div>
            <Typography.Text className="mr-5">
              Terms & Conditions
            </Typography.Text>
            <span className="text-secondary">• </span>
            <Typography.Text className="ml-5">Privacy Policy</Typography.Text>
          </div>
          <Typography.Text className="signup-text fs-12">
            © {new Date().getFullYear()}, Winrate. All Rights Reserved.
          </Typography.Text>
        </Row>
      </div>
    </div>
  );
};

export default VerifyEmail;

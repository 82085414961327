import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import MainLayout from '../layout/MainLayout';

// Define the custom interface for the props
interface MainLayoutRouteProps {
  element: React.ReactNode;
}

const MainLayoutRoute: React.FC<MainLayoutRouteProps> = ({
  element,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // Use element directly
  return (
    <MainLayout>
      {React.cloneElement(element as React.ReactElement, {
        navigate,
        location,
        params,
        ...rest,
      })}
    </MainLayout>
  );
};

export default MainLayoutRoute;

import winrateLogo from './icons/winrate-logo.svg';
import winrateLogoCollapse from './icons/winrate-logo-collapse.svg';
import dashboardIcon from './navigation-icons/dashboard.svg';
import dealroomIcon from './navigation-icons/dealroom.svg';
import integrationIcon from './navigation-icons/integration.svg';
import unfoldIcon from './icons/unfold.svg';
import userManagementIcon from './navigation-icons/user-management.svg';
import foldIcon from './icons/fold.svg';
import bellIcon from './icons/bell-icon.svg';
import helpIcon from './icons/help-icon.svg';
import bannerImage from './users-icons/banner-image.svg';
import usersTab from './users-icons/user-tab-icon.svg';
import usersTabActive from './users-icons/user-tabactive-icon.svg';
import teamTab from './users-icons/team-tab-icon.svg';
import teamTabActive from './users-icons/team-tabactive-icon.svg';
import accessGroupTab from './users-icons/accessgroup-tab-icon.svg';
import accessGroupTabActive from './users-icons/accessgroup-tabactive-icon.svg';
import invitedIcon from './users-icons/invited-icon.svg';
import deactivatedIcon from './users-icons/deactivated-icon.svg';
import orgChartIcon from './users-icons/orgchart-icon.svg';
import activeIcon from './users-icons/active-icon.svg';
import rightArrowIcon from './users-icons/rightarrow-icon.svg';
import leftArrowIcon from './users-icons/leftarrow-icon.svg';
import nextIcon from './users-icons/next-icon.svg';
import selectedDashboardIcon from './navigation-icons/selected-dashboard.svg';
import selectedDealroomIcon from './navigation-icons/selected-dealroom.svg';
import selectedUserManagerIcon from './navigation-icons/selected-usermanagement.svg';
import selectedIntegrationIcon from './navigation-icons/selected-integration.svg';
import teamBannerImage from './teams-icons/team-banner.svg';
import firstTeamPlaceholder from './teams-icons/teamPlaceholder1.svg';
import arrowIcon from './teams-icons/arrow-icon.svg';
import noTeamPlaceholder from './teams-icons/teamPlaceholder2.svg';
import profileIcon from './teams-icons/profile-icon-temp.svg';
import pencilIcon from './teams-icons/pencil-icon.svg';
import createTeamBanner from './teams-icons/createteam-banner.svg';
import salesNinja from './teams-icons/salesninja.svg';
import dealCloser from './teams-icons/image2.jpg';
import accessgroupBanner from './accessgroup-icons/accessgroup-banner.svg';
import coverImg from './teams-icons/cover-img.jpg';
import greenCircle from './teams-icons/green-circle.svg';
import partyPopper from './teams-icons/party-popper.svg';
import noEntry from './teams-icons/no-entry.svg';
import fire from './teams-icons/fire.svg';
import snowFlake from './teams-icons/snow-flake.svg';
import listIcon from './teams-icons/list-icon.svg';
import gridIcon from './teams-icons/grid-icon.svg';
import authBG from './auth/auth-bg.svg';
import sidePlaceholder from './auth/side-placeholder.svg';
import loginLogo from './auth/winrate-logo.svg';
import emailIcon from './auth/email-icon.svg';
import google from './auth/google.svg';
import slack from './auth/slack.svg';
import salesForce from './auth/salesforce.svg';
import hubSpot from './auth/hubspot.svg';
import connectedIcon from './auth/connected-icon.svg';
import calendarPlaceholder from './auth/calendar-placeholder.svg';
import resetPassowordIcon from './auth/reset-password-icon.svg';
import prepNoteIcon from './events-icons/prepnote-icon.svg';
import filterIcon from './events-icons/filter-icon.svg';
import meetingIcon from './events-icons/meeting-icon.svg';
import hubspotIcon from './icons/hubspot.svg';
import gmailIcon from './icons/gmail.svg';
import refreshIcon from './events-icons/refresh-icon.svg';
import summaryGeneratedIcon from './events-icons/summary-generated.svg';
import calendarIcon from './events-icons/calendar-icon.svg';
import noMettingIcon from './events-icons/no-meeting-icon.svg';
import noMeetingFoundIcon from './events-icons/no-meeting-found.svg';
import noSearchIcon from './events-icons/no-search-icon.svg';

export const WinrateImages = {
  Icons: {
    winrateLogo,
    winrateLogoCollapse,
    dashboardIcon,
    dealroomIcon,
    integrationIcon,
    unfoldIcon,
    userManagementIcon,
    foldIcon,
    bellIcon,
    helpIcon,
    selectedDashboardIcon,
    selectedDealroomIcon,
    selectedUserManagerIcon,
    selectedIntegrationIcon,
    hubspotIcon,
    gmailIcon,
  },
  UsersIcons: {
    bannerImage,
    usersTab,
    usersTabActive,
    teamTab,
    teamTabActive,
    accessGroupTab,
    accessGroupTabActive,
    invitedIcon,
    deactivatedIcon,
    orgChartIcon,
    activeIcon,
    rightArrowIcon,
    leftArrowIcon,
    nextIcon,
  },
  TeamIcons: {
    teamBannerImage,
    firstTeamPlaceholder,
    arrowIcon,
    noTeamPlaceholder,
    profileIcon,
    pencilIcon,
    createTeamBanner,
    salesNinja,
    dealCloser,
    coverImg,
    greenCircle,
    partyPopper,
    noEntry,
    fire,
    snowFlake,
    listIcon,
    gridIcon,
  },
  AccessGroupIcons: {
    accessgroupBanner,
  },
  Auth: {
    authBG,
    sidePlaceholder,
    loginLogo,
    emailIcon,
    google,
    slack,
    salesForce,
    hubSpot,
    connectedIcon,
    calendarPlaceholder,
    resetPassowordIcon,
  },
  EventIcons: {
    prepNoteIcon,
    filterIcon,
    meetingIcon,
    refreshIcon,
    summaryGeneratedIcon,
    calendarIcon,
    noMettingIcon,
    noMeetingFoundIcon,
    noSearchIcon,
  },
};

import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
export interface authState {
  calendarToken: string;
  userID: string;
  meetingEvents: any;
  monthDate: any;
  showCurrentMonth: any;
  calendarMonth: any;
  monthsDate: any;
  selectedDate: any;
  selectedMonth: any;
  dateChange: boolean;
  startDate: string;
  endDate: string;
  checkedDomain: string[];
  prepnoteSelectedDate: any;
}

const initialState: authState = {
  calendarToken: '',
  userID: '',
  meetingEvents: [],
  monthDate: '',
  showCurrentMonth: null,
  calendarMonth: null,
  monthsDate: new Date(),
  selectedDate: dayjs(),
  selectedMonth: dayjs(),
  dateChange: false,
  startDate: '',
  endDate: '',
  checkedDomain: [],
  prepnoteSelectedDate: dayjs(),
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setCalendarToken: (state, { payload }) => {
      state.calendarToken = payload;
    },
    setUserID: (state, { payload }) => {
      state.userID = payload;
    },
    setMeetingEvents: (state, { payload }) => {
      state.meetingEvents = payload;
    },

    setSelectedDate: (state, { payload }) => {
      state.selectedDate = payload;
    },
    setSelectedMonth: (state, { payload }) => {
      state.selectedMonth = payload;
    },
    setDateChange: (state, { payload }) => {
      state.dateChange = payload;
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload;
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload;
    },
    setCheckedDomain: (state, { payload }) => {
      state.checkedDomain = payload;
    },
    setprepnoteSelectedDate: (state, { payload }) => {
      state.prepnoteSelectedDate = payload;
    },
  },
});

export const {
  setCalendarToken,
  setUserID,
  setMeetingEvents,
  setSelectedMonth,
  setSelectedDate,
  setDateChange,
  setStartDate,
  setEndDate,
  setCheckedDomain,
  setprepnoteSelectedDate,
} = authSlice.actions;
export default authSlice.reducer;

import { Col, Select, Switch, Typography } from 'antd';
import './notification.less';

const { Text } = Typography;

const Reuse = ({
  mess,
  key,
  disabled,
}: {
  mess: string;
  key: string;
  disabled: boolean;
}) => {
  return (
    <Col span={24} key={key} className="reuse-item">
      <div className="reuse-text">
        <Text>{mess}</Text>
        <Select
          options={[
            { label: '1 Hour before', value: '1_hour' },
            { label: '30 mins before', value: '30_mins' },
            { label: '15 mins before', value: '15_mins' },
          ]}
          disabled={disabled} // Apply disabled state
          placeholder="Select Reminder Time"
          style={{ width: 200 }} // Optional: adjust the width as needed
        />
      </div>
      <Switch disabled={disabled} /> {/* Apply disabled state */}
    </Col>
  );
};

export default Reuse;

/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';

import { Input, Select, Space } from 'antd';

interface ICountryCode {
  label: string;
  value: string;
}

// country code list
const countryCodeList: ICountryCode[] = [
  {
    label: '+1',
    value: '1',
  },
  {
    label: '+91',
    value: '91',
  },
];

interface IProps {
  name: string;
  label: string;
  placeholder: string;
  value: string;
  handleChange: (val: string) => void;
}

type IState = {
  code: string;
  number: string;
};

const PhoneInput: React.FC<IProps> = ({
  name,
  label,
  placeholder,
  value,
  handleChange,
}) => {
  const [{ code, number }, setState] = useState<IState>({
    code: '1',
    number: '',
  });

  const handleCodeChange = (val: string) => {
    setState((prevState) => ({
      ...prevState,
      code: val,
    }));
  };

  const handleInputChange = (
    code: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let val = e.target.value.replace(/\D/g, ''); // Remove non-digit characters for consistency
    if (code === '91') {
      setState((prevState) => ({
        ...prevState,
        number: val,
      }));
    } else {
      if (val.length > 3 && val.length <= 6) {
        val = `(${val.slice(0, 3)}) ${val.slice(3)}`;
      } else if (val.length > 6) {
        val = `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6, 10)}`;
      } else if (val.length > 0) {
        val = `(${val}`;
      }

      setState((prevState) => ({
        ...prevState,
        number: val,
      }));
    }
  };

  useEffect(() => {
    if (value) {
      const valArr = value.split(':');
      setState({
        code: valArr[0],
        number: valArr[1],
      });
    }
  }, []);

  useEffect(() => {
    handleChange(`${code}:${number}`);
  }, [code, number]);

  useEffect(() => {
    if (!value) {
      setState({
        code: '1',
        number: '',
      });
    }
  }, [value]);

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Select
        defaultValue="1"
        value={code}
        style={{ width: '20%' }}
        options={countryCodeList}
        onChange={handleCodeChange}
      />
      {code === '91' ? (
        <Input
          type="number"
          name={name}
          id="indphone"
          aria-label={label}
          placeholder={placeholder}
          defaultValue="xxxxx"
          value={number}
          onChange={(e) => handleInputChange(code, e)}
          maxLength={10}
        />
      ) : (
        <Input
          type="tel"
          name={name}
          id="usphone"
          aria-label={label}
          placeholder={placeholder}
          defaultValue="xxxxx"
          value={number}
          onChange={(e) => handleInputChange(code, e)}
          maxLength={14}
        />
      )}
    </Space.Compact>
  );
};

export default PhoneInput;

import { WinrateImages } from '../../assets/Images';
import React, { useState } from 'react';
import { Button, Col, Form, Input, message, Row, Typography } from 'antd';
import PrimaryButton from '../../common/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from 'aws-amplify/auth';

const ForgotPassword: React.FC = () => {
  const { authBG, sidePlaceholder, loginLogo } = WinrateImages.Auth;
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  //function to reset password
  const handleResetPassword = async (e: { email: string }) => {
    const emailData = e.email.toLowerCase();
    setLoading(true);
    try {
      await resetPassword({ username: emailData });
      setLoading(false);
      navigate('/reset-password', { state: { userName: emailData } });
    } catch (error: any) {
      message.error(error.message);
    }
  };

  return (
    <div className="auth-page">
      <img src={authBG} alt="" className="auth-bg" />
      <Row gutter={16} className="auth-container">
        <Col xs={24} md={12} className="login-form-container">
          <div className="login-form">
            <img src={loginLogo} alt="Winrate Logo" className="logo mb-20" />
            <Title level={4} className="mb-0 fw-500">
              Forgot Password?
            </Title>
            <Text type="secondary">
              No worries, we will send you a verification code to confirm your
              email.
            </Text>
            <Form
              layout="vertical"
              requiredMark={false}
              className="login-form-fields mt-24"
              onFinish={(e) => handleResetPassword(e)}
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  {
                    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                    message: 'Please enter a valid email address!',
                  },
                ]}
              >
                <Input placeholder="your@company.com" />
              </Form.Item>

              <Form.Item className="reset-pass">
                <PrimaryButton
                  text="Reset Password"
                  className="login-btn"
                  loading={loading}
                />
              </Form.Item>

              <Row align="middle" justify="center">
                <Button
                  type="text"
                  className="text-secondary"
                  onClick={() => navigate('/login')}
                >
                  Back to Login
                </Button>
              </Row>
            </Form>
            <div className="footer-links forgot-password-footer">
              <Typography.Text className="mr-5">
                Terms & Conditions
              </Typography.Text>
              <span className="text-secondary">• </span>
              <Typography.Text className="ml-5">Privacy Policy</Typography.Text>
            </div>
            <Text className="signup-text fs-12 mt-10">
              © {new Date().getFullYear()}, Winrate. All Rights Reserved.
            </Text>
          </div>
        </Col>
        <Col xs={0} md={12} className="image-container">
          <div className="image-content">
            <img src={sidePlaceholder} alt="Right Side Illustration" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
